.ui-table {
  height:100%;
  overflow: auto;
  background: var(--ui-container);
  display: grid;
  border: 1px solid var(--ui-border);
  font-family: sans-serif;
  border-radius: 4px;
  &-actions {
    padding: 10px 8px!important;
    div:not(:last-child){
      margin-right:7px;
    }
    display: flex;
    div {
      height:24px;
      min-width:24px;
      border-radius: 4px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--ui-border);
    }
  }
  table {
    border-collapse: collapse;
    color: var(--ui-text);
    font-size: 14px;
    thead tr th {
      padding: 8px;
      text-align: left;
      background: var(--ui-container);
      white-space: nowrap;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      font-weight: 600;
      z-index: 1;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid var(--ui-border);
      }
    }
    tbody tr {
      background: var(--ui-container);
      transition: .2s;
      cursor: pointer;
      border-bottom: 1px solid var(--ui-border);
    }

    tbody td {
      padding: 14px 8px;
      &:not(:last-child){
        border-right: 1px solid var(--ui-border);
      }
      white-space: nowrap;
      overflow: auto;
      max-width: 350px;
    }
    tbody tr:hover {
      background: var(--ui-bg)!important;
    }
  }
  &-compact {
    table {
      font-size: 12px;
      tbody td {
        padding: 8px;
      }
    }
    .ui-table-actions {
      padding: 8px!important;
      div:not(:last-child){
        margin-right:6px;
      }
      div {
        height:20px;
        min-width:20px;
      }
    }
  }
}