.portal-home-container {
  display: grid;
  grid-template-rows: min-content min-content min-content auto;
  grid-gap: 150px;
  @media only screen and (max-width: 600px){ 
    grid-gap: 75px;
  }
  .navigation {
    display: grid;
    align-items: center;
    grid-template-columns: min-content auto min-content;
    > .sign-out-button {
      cursor: pointer;
      padding: 10px;
      display: flex;
      align-items: center;
      font-size: 18px;
      transition: 0.2s;
      border-radius: 2px;
      &:hover {
        background: var(--ui-container);
      }
    }
    > .user-button {
      justify-self: flex-end;
      display: flex;
      align-items: center;
      font-size: 18px;
      cursor: pointer;
      i {
        margin-right: 10px;
      }
      padding:10px 20px;
      transition: 0.2s;
      border-radius: 2px;
      &:hover {
        background: var(--ui-container);
      }
      @media only screen and (max-width: 600px){
        p {
          display: none;
        }
        i {
          margin-right: 0;
        }
      }
    }
    padding: 50px 0px 0px 0px;
  }
  .heading {
    text-align: center;
  }
  .dashboard {
    display: grid;
    grid-template-columns: auto 45%;
    @media only screen and (max-width: 800px){
      grid-template-columns: 100%;
    }
    @media only screen and (max-width: 1100px){
      display: flex;
      flex-direction: column;
    }
    @media only screen and (max-width: 650px){
      .apps .tiles {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  .notifications {
    display: grid;
    grid-template-rows: min-content auto;
  }
  .apps .tiles {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}