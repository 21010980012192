.ui {
  &-card {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    background-color: var(--ui-container);
    border-radius: 12px;

    &-transparent {
      background-color: var(--ui-container-transparent);
    }

    &-message {
      text-align: center;
      align-content: center;
      align-items: unset;
      p {
        font-family: $font-primary;
        color: var(--ui-text-lighter);
        font-weight: 300;
        font-size: 16px;
      }
      i {
        color: var(--ui-text);
        font-size: 32px;
        margin-bottom:16px;
      }
    }
  }
}