@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://green-cdn.fra1.digitaloceanspaces.com/fonts/751832/0EE16F2042BD93429.css);
:root {
  --theme-green: #30AF5F;
  --theme-olive: #A0CC3A;
  --theme-purple: #4E59A3;
  --theme-blue: #37A9E0;
  --theme-orange: #E67F25;
  --theme-grey: #4C494B;
  --theme-white: #ffffff; }

[data-theme="light"] {
  --ui-text-lighter: #948f93;
  --ui-border: #e4dee3;
  --ui-text: #4C494B;
  --ui-bg: #fafafa;
  --ui-container: #ffffff;
  --ui-container-lighter: #f0f0f0;
  --ui-container-transparent: #fffffff7; }

[data-theme="midnight"] {
  --ui-text-lighter: #9b9b9b;
  --ui-border: #222222;
  --ui-text: white;
  --ui-bg: #000000;
  --ui-container: #0a0a0a;
  --ui-container-lighter: #1f1f1f;
  --ui-container-transparent: #181818f7; }

[data-theme="dark"] {
  --ui-text-lighter: #5b5b66;
  --ui-border: #363644;
  --ui-container-lighter: #43434d;
  --ui-text: white;
  --ui-bg: #212125;
  --ui-container: #28282e;
  --ui-container-transparent: #28282ef7; }

.ui-title, .ui-title-mega, .ui-title-one, .ui-title-two, .ui-title-three, .ui-title-four, .ui-title-five, .ui-title-sub {
  color: var(--ui-text);
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-weight: 500;
  letter-spacing: -.045em; }
  .ui-title-mega {
    font-size: 3em; }
    @media only screen and (max-width: 600px) {
      .ui-title-mega {
        font-size: 1.5em; } }
  @media only screen and (max-width: 600px) {
    .ui-title-one {
      font-size: 1.3em; } }
  .ui-title-two {
    font-size: 1.75em; }
    @media only screen and (max-width: 600px) {
      .ui-title-two {
        font-size: 1.2em; } }
  .ui-title-three {
    font-size: 1.5em; }
    @media only screen and (max-width: 600px) {
      .ui-title-three {
        font-size: 1.1em; } }
  .ui-title-four {
    font-size: 1.25em; }
    @media only screen and (max-width: 600px) {
      .ui-title-four {
        font-size: 1em; } }
  .ui-title-five {
    font-size: 1.1em; }
    @media only screen and (max-width: 600px) {
      .ui-title-five {
        font-size: .9em; } }
  .ui-title-sub {
    color: var(--ui-text-lighter);
    font-weight: 300; }

.ui-label {
  color: var(--ui-text);
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  font-weight: 500; }

.ui-body {
  color: var(--ui-text);
  font-family: "Roboto", sans-serif;
  font-size: .9em;
  font-weight: 500;
  line-height: 1.6; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0; }

.ui-input, .ui-select, .ui-textarea {
  -webkit-appearance: none;
  color: var(--ui-text);
  font-family: "Roboto", sans-serif;
  border: 1px solid var(--ui-border);
  font-size: 1em;
  height: 41px;
  padding: 10px 12px;
  outline: 0;
  background: var(--ui-bg);
  resize: none;
  transition: .2s;
  border-radius: 0;
  width: 100%; }
  .ui-input:hover:not([disabled]), .ui-input:focus, .ui-select:hover:not([disabled]), .ui-select:focus, .ui-textarea:hover:not([disabled]), .ui-textarea:focus {
    background-color: var(--ui-container); }
  .ui-input.warning, .ui-select.warning, .ui-textarea.warning {
    border: 1px solid #E67F25; }

.ui-img-preview {
  border-radius: 12px;
  padding: 18px;
  max-width: 240px;
  height: 80px;
  transition: .25s;
  display: flex;
  justify-content: center;
  cursor: pointer; }
  .ui-img-preview img {
    max-width: 100%;
    max-height: 100%; }
  .ui-img-preview:hover {
    opacity: .75; }

.ui-select {
  background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" data-prefix="far" data-icon="angle-down" class="svg-inline--fa fa-angle-down fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="" d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z"></path></svg>');
  background-size: 12px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: calc(100% - 14px);
  background-clip: padding-box;
  padding-right: 40px; }
  .ui-select[disabled] {
    opacity: .5; }

.ui-btn, .ui-btn-primary, .ui-btn-secondary, .ui-btn-compact {
  text-decoration: none;
  -webkit-appearance: none;
  background-color: var(--ui-container);
  border: 1px solid var(--ui-border);
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  color: var(--ui-text);
  font-size: 16px;
  padding: 12px 20px;
  border-radius: 2px;
  font-weight: 300;
  outline: 0;
  cursor: pointer;
  transition: .2s; }
  .ui-btn:hover, .ui-btn-primary:hover, .ui-btn-secondary:hover, .ui-btn-compact:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.115); }
  .ui-btn:active, .ui-btn-primary:active, .ui-btn-secondary:active, .ui-btn-compact:active {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1); }
  .ui-btn-primary {
    background-color: #30AF5F;
    color: white; }
  .ui-btn-secondary {
    background-color: #37A9E0;
    color: white; }
  .ui-btn-compact {
    padding: 12px 12px;
    font-size: 14px;
    border-radius: 8px; }

[data-theme="dark"] .ui-select {
  background-image: url('data:image/svg+xml;utf8,<svg fill="white" aria-hidden="true" data-prefix="far" data-icon="angle-down" class="svg-inline--fa fa-angle-down fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z"></path></svg>'); }

.ui-input:-webkit-autofill,
.ui-input:-webkit-autofill:hover,
.ui-input:-webkit-autofill:focus,
.ui-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--ui-bg) inset !important; }

.ui-input:-webkit-autofill {
  -webkit-text-fill-color: var(--ui-text) !important; }

.ui-input::-webkit-input-placeholder {
  color: var(--ui-text-lighter); }

.ui-input:-ms-input-placeholder {
  color: var(--ui-text-lighter); }

.ui-input::-ms-input-placeholder {
  color: var(--ui-text-lighter); }

.ui-input::placeholder {
  color: var(--ui-text-lighter); }

.transition-1 {
  transition: 0.1s linear; }

.transition-2 {
  transition: 0.2s linear; }

.transition-3 {
  transition: 0.3s linear; }

.transition-4 {
  transition: 0.4s linear; }

.transition-5 {
  transition: 0.5s linear; }

.transition-6 {
  transition: 0.6s linear; }

.transition-7 {
  transition: 0.7s linear; }

.transition-8 {
  transition: 0.8s linear; }

.transition-9 {
  transition: 0.9s linear; }

.duration-1 {
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important; }

.duration-2 {
  -webkit-animation-duration: 0.2s !important;
          animation-duration: 0.2s !important; }

.duration-3 {
  -webkit-animation-duration: 0.3s !important;
          animation-duration: 0.3s !important; }

.duration-4 {
  -webkit-animation-duration: 0.4s !important;
          animation-duration: 0.4s !important; }

.duration-5 {
  -webkit-animation-duration: 0.5s !important;
          animation-duration: 0.5s !important; }

.duration-6 {
  -webkit-animation-duration: 0.6s !important;
          animation-duration: 0.6s !important; }

.duration-7 {
  -webkit-animation-duration: 0.7s !important;
          animation-duration: 0.7s !important; }

.duration-8 {
  -webkit-animation-duration: 0.8s !important;
          animation-duration: 0.8s !important; }

.duration-9 {
  -webkit-animation-duration: 0.9s !important;
          animation-duration: 0.9s !important; }

.animation-delay-1 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }

.animation-delay-2 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

.animation-delay-3 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }

.animation-delay-4 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }

.animation-delay-5 {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s; }

.animation-delay-6 {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s; }

.animation-delay-7 {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s; }

.animation-delay-8 {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s; }

.animation-delay-9 {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s; }

.animation-delay-10 {
  -webkit-animation-delay: 1s;
          animation-delay: 1s; }

.animation-delay-11 {
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s; }

.animation-delay-12 {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s; }

.animation-delay-13 {
  -webkit-animation-delay: 1.3s;
          animation-delay: 1.3s; }

.animation-delay-14 {
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s; }

.animation-delay-15 {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s; }

.animation-delay-16 {
  -webkit-animation-delay: 1.6s;
          animation-delay: 1.6s; }

.animation-delay-17 {
  -webkit-animation-delay: 1.7s;
          animation-delay: 1.7s; }

.animation-delay-18 {
  -webkit-animation-delay: 1.8s;
          animation-delay: 1.8s; }

.animation-delay-19 {
  -webkit-animation-delay: 1.9s;
          animation-delay: 1.9s; }

.animation-delay-20 {
  -webkit-animation-delay: 2s;
          animation-delay: 2s; }

.animation-delay-21 {
  -webkit-animation-delay: 2.1s;
          animation-delay: 2.1s; }

.animation-delay-22 {
  -webkit-animation-delay: 2.2s;
          animation-delay: 2.2s; }

.animation-delay-23 {
  -webkit-animation-delay: 2.3s;
          animation-delay: 2.3s; }

.animation-delay-24 {
  -webkit-animation-delay: 2.4s;
          animation-delay: 2.4s; }

.animation-delay-25 {
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s; }

.animation-delay-26 {
  -webkit-animation-delay: 2.6s;
          animation-delay: 2.6s; }

.animation-delay-27 {
  -webkit-animation-delay: 2.7s;
          animation-delay: 2.7s; }

.animation-delay-28 {
  -webkit-animation-delay: 2.8s;
          animation-delay: 2.8s; }

.animation-delay-29 {
  -webkit-animation-delay: 2.9s;
          animation-delay: 2.9s; }

.hidden {
  visibility: hidden;
  opacity: 0; }

.display {
  visibility: visible;
  opacity: 1; }

.opacity-1 {
  opacity: 0.01; }

.opacity-2 {
  opacity: 0.02; }

.opacity-3 {
  opacity: 0.03; }

.opacity-4 {
  opacity: 0.04; }

.opacity-5 {
  opacity: 0.05; }

.opacity-6 {
  opacity: 0.06; }

.opacity-7 {
  opacity: 0.07; }

.opacity-8 {
  opacity: 0.08; }

.opacity-9 {
  opacity: 0.09; }

.opacity-10 {
  opacity: 0.1; }

.opacity-11 {
  opacity: 0.11; }

.opacity-12 {
  opacity: 0.12; }

.opacity-13 {
  opacity: 0.13; }

.opacity-14 {
  opacity: 0.14; }

.opacity-15 {
  opacity: 0.15; }

.opacity-16 {
  opacity: 0.16; }

.opacity-17 {
  opacity: 0.17; }

.opacity-18 {
  opacity: 0.18; }

.opacity-19 {
  opacity: 0.19; }

.opacity-20 {
  opacity: 0.2; }

.opacity-21 {
  opacity: 0.21; }

.opacity-22 {
  opacity: 0.22; }

.opacity-23 {
  opacity: 0.23; }

.opacity-24 {
  opacity: 0.24; }

.opacity-25 {
  opacity: 0.25; }

.opacity-26 {
  opacity: 0.26; }

.opacity-27 {
  opacity: 0.27; }

.opacity-28 {
  opacity: 0.28; }

.opacity-29 {
  opacity: 0.29; }

.opacity-30 {
  opacity: 0.3; }

.opacity-31 {
  opacity: 0.31; }

.opacity-32 {
  opacity: 0.32; }

.opacity-33 {
  opacity: 0.33; }

.opacity-34 {
  opacity: 0.34; }

.opacity-35 {
  opacity: 0.35; }

.opacity-36 {
  opacity: 0.36; }

.opacity-37 {
  opacity: 0.37; }

.opacity-38 {
  opacity: 0.38; }

.opacity-39 {
  opacity: 0.39; }

.opacity-40 {
  opacity: 0.4; }

.opacity-41 {
  opacity: 0.41; }

.opacity-42 {
  opacity: 0.42; }

.opacity-43 {
  opacity: 0.43; }

.opacity-44 {
  opacity: 0.44; }

.opacity-45 {
  opacity: 0.45; }

.opacity-46 {
  opacity: 0.46; }

.opacity-47 {
  opacity: 0.47; }

.opacity-48 {
  opacity: 0.48; }

.opacity-49 {
  opacity: 0.49; }

.opacity-50 {
  opacity: 0.5; }

.opacity-51 {
  opacity: 0.51; }

.opacity-52 {
  opacity: 0.52; }

.opacity-53 {
  opacity: 0.53; }

.opacity-54 {
  opacity: 0.54; }

.opacity-55 {
  opacity: 0.55; }

.opacity-56 {
  opacity: 0.56; }

.opacity-57 {
  opacity: 0.57; }

.opacity-58 {
  opacity: 0.58; }

.opacity-59 {
  opacity: 0.59; }

.opacity-60 {
  opacity: 0.6; }

.opacity-61 {
  opacity: 0.61; }

.opacity-62 {
  opacity: 0.62; }

.opacity-63 {
  opacity: 0.63; }

.opacity-64 {
  opacity: 0.64; }

.opacity-65 {
  opacity: 0.65; }

.opacity-66 {
  opacity: 0.66; }

.opacity-67 {
  opacity: 0.67; }

.opacity-68 {
  opacity: 0.68; }

.opacity-69 {
  opacity: 0.69; }

.opacity-70 {
  opacity: 0.7; }

.opacity-71 {
  opacity: 0.71; }

.opacity-72 {
  opacity: 0.72; }

.opacity-73 {
  opacity: 0.73; }

.opacity-74 {
  opacity: 0.74; }

.opacity-75 {
  opacity: 0.75; }

.opacity-76 {
  opacity: 0.76; }

.opacity-77 {
  opacity: 0.77; }

.opacity-78 {
  opacity: 0.78; }

.opacity-79 {
  opacity: 0.79; }

.opacity-80 {
  opacity: 0.8; }

.opacity-81 {
  opacity: 0.81; }

.opacity-82 {
  opacity: 0.82; }

.opacity-83 {
  opacity: 0.83; }

.opacity-84 {
  opacity: 0.84; }

.opacity-85 {
  opacity: 0.85; }

.opacity-86 {
  opacity: 0.86; }

.opacity-87 {
  opacity: 0.87; }

.opacity-88 {
  opacity: 0.88; }

.opacity-89 {
  opacity: 0.89; }

.opacity-90 {
  opacity: 0.9; }

.opacity-91 {
  opacity: 0.91; }

.opacity-92 {
  opacity: 0.92; }

.opacity-93 {
  opacity: 0.93; }

.opacity-94 {
  opacity: 0.94; }

.opacity-95 {
  opacity: 0.95; }

.opacity-96 {
  opacity: 0.96; }

.opacity-97 {
  opacity: 0.97; }

.opacity-98 {
  opacity: 0.98; }

.opacity-99 {
  opacity: 0.99; }

.opacity-100 {
  opacity: 1; }

.fade-in {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fade-in;
  animation-name: fade-in; }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.bg-green {
  background-color: #30AF5F !important; }

.bg-olive {
  background-color: #A0CC3A !important; }

.bg-purple {
  background-color: #4E59A3 !important; }

.bg-blue {
  background-color: #37A9E0 !important; }

.bg-orange {
  background-color: #E67F25 !important; }

.bg-grey {
  background-color: #4C494B !important; }

.bg-white {
  background-color: #ffffff !important; }

.colour-green {
  color: #30AF5F !important; }

.colour-olive {
  color: #A0CC3A !important; }

.colour-purple {
  color: #4E59A3 !important; }

.colour-blue {
  color: #37A9E0 !important; }

.colour-orange {
  color: #E67F25 !important; }

.colour-grey {
  color: #4C494B !important; }

.colour-white {
  color: #ffffff !important; }

.col {
  display: grid; }
  .col-1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr); }
    @media only screen and (max-width: 1000px) {
      .col-1 {
        grid-template-columns: 1fr; } }
    .col-1-min {
      display: grid;
      grid-template-columns: repeat(1, -webkit-min-content);
      grid-template-columns: repeat(1, min-content); }
    .col-1-max {
      display: grid;
      grid-template-columns: repeat(1, -webkit-max-content);
      grid-template-columns: repeat(1, max-content); }
  .col-start-1 {
    grid-column-start: 1; }
  .col-end-1 {
    grid-column-end: 2; }
  .col-start-md-1 {
    grid-column-start: 1; }
    @media only screen and (max-width: 750px) {
      .col-start-md-1 {
        grid-column-start: 1; } }
  .col-end-md-1 {
    grid-column-end: 2; }
    @media only screen and (max-width: 750px) {
      .col-end-md-1 {
        grid-column-end: 1; } }
  .col-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr); }
    @media only screen and (max-width: 1000px) {
      .col-2 {
        grid-template-columns: 1fr; } }
    .col-2-min {
      display: grid;
      grid-template-columns: repeat(2, -webkit-min-content);
      grid-template-columns: repeat(2, min-content); }
    .col-2-max {
      display: grid;
      grid-template-columns: repeat(2, -webkit-max-content);
      grid-template-columns: repeat(2, max-content); }
  .col-start-2 {
    grid-column-start: 2; }
  .col-end-2 {
    grid-column-end: 3; }
  .col-start-md-2 {
    grid-column-start: 2; }
    @media only screen and (max-width: 750px) {
      .col-start-md-2 {
        grid-column-start: 1; } }
  .col-end-md-2 {
    grid-column-end: 3; }
    @media only screen and (max-width: 750px) {
      .col-end-md-2 {
        grid-column-end: 1; } }
  .col-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr); }
    @media only screen and (max-width: 1000px) {
      .col-3 {
        grid-template-columns: 1fr; } }
    .col-3-min {
      display: grid;
      grid-template-columns: repeat(3, -webkit-min-content);
      grid-template-columns: repeat(3, min-content); }
    .col-3-max {
      display: grid;
      grid-template-columns: repeat(3, -webkit-max-content);
      grid-template-columns: repeat(3, max-content); }
  .col-start-3 {
    grid-column-start: 3; }
  .col-end-3 {
    grid-column-end: 4; }
  .col-start-md-3 {
    grid-column-start: 3; }
    @media only screen and (max-width: 750px) {
      .col-start-md-3 {
        grid-column-start: 1; } }
  .col-end-md-3 {
    grid-column-end: 4; }
    @media only screen and (max-width: 750px) {
      .col-end-md-3 {
        grid-column-end: 1; } }
  .col-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr); }
    @media only screen and (max-width: 1000px) {
      .col-4 {
        grid-template-columns: 1fr; } }
    .col-4-min {
      display: grid;
      grid-template-columns: repeat(4, -webkit-min-content);
      grid-template-columns: repeat(4, min-content); }
    .col-4-max {
      display: grid;
      grid-template-columns: repeat(4, -webkit-max-content);
      grid-template-columns: repeat(4, max-content); }
  .col-start-4 {
    grid-column-start: 4; }
  .col-end-4 {
    grid-column-end: 5; }
  .col-start-md-4 {
    grid-column-start: 4; }
    @media only screen and (max-width: 750px) {
      .col-start-md-4 {
        grid-column-start: 1; } }
  .col-end-md-4 {
    grid-column-end: 5; }
    @media only screen and (max-width: 750px) {
      .col-end-md-4 {
        grid-column-end: 1; } }
  .col-5 {
    display: grid;
    grid-template-columns: repeat(5, 1fr); }
    @media only screen and (max-width: 1000px) {
      .col-5 {
        grid-template-columns: 1fr; } }
    .col-5-min {
      display: grid;
      grid-template-columns: repeat(5, -webkit-min-content);
      grid-template-columns: repeat(5, min-content); }
    .col-5-max {
      display: grid;
      grid-template-columns: repeat(5, -webkit-max-content);
      grid-template-columns: repeat(5, max-content); }
  .col-start-5 {
    grid-column-start: 5; }
  .col-end-5 {
    grid-column-end: 6; }
  .col-start-md-5 {
    grid-column-start: 5; }
    @media only screen and (max-width: 750px) {
      .col-start-md-5 {
        grid-column-start: 1; } }
  .col-end-md-5 {
    grid-column-end: 6; }
    @media only screen and (max-width: 750px) {
      .col-end-md-5 {
        grid-column-end: 1; } }
  .col-6 {
    display: grid;
    grid-template-columns: repeat(6, 1fr); }
    @media only screen and (max-width: 1000px) {
      .col-6 {
        grid-template-columns: 1fr; } }
    .col-6-min {
      display: grid;
      grid-template-columns: repeat(6, -webkit-min-content);
      grid-template-columns: repeat(6, min-content); }
    .col-6-max {
      display: grid;
      grid-template-columns: repeat(6, -webkit-max-content);
      grid-template-columns: repeat(6, max-content); }
  .col-start-6 {
    grid-column-start: 6; }
  .col-end-6 {
    grid-column-end: 7; }
  .col-start-md-6 {
    grid-column-start: 6; }
    @media only screen and (max-width: 750px) {
      .col-start-md-6 {
        grid-column-start: 1; } }
  .col-end-md-6 {
    grid-column-end: 7; }
    @media only screen and (max-width: 750px) {
      .col-end-md-6 {
        grid-column-end: 1; } }
  .col-7 {
    display: grid;
    grid-template-columns: repeat(7, 1fr); }
    @media only screen and (max-width: 1000px) {
      .col-7 {
        grid-template-columns: 1fr; } }
    .col-7-min {
      display: grid;
      grid-template-columns: repeat(7, -webkit-min-content);
      grid-template-columns: repeat(7, min-content); }
    .col-7-max {
      display: grid;
      grid-template-columns: repeat(7, -webkit-max-content);
      grid-template-columns: repeat(7, max-content); }
  .col-start-7 {
    grid-column-start: 7; }
  .col-end-7 {
    grid-column-end: 8; }
  .col-start-md-7 {
    grid-column-start: 7; }
    @media only screen and (max-width: 750px) {
      .col-start-md-7 {
        grid-column-start: 1; } }
  .col-end-md-7 {
    grid-column-end: 8; }
    @media only screen and (max-width: 750px) {
      .col-end-md-7 {
        grid-column-end: 1; } }
  .col-8 {
    display: grid;
    grid-template-columns: repeat(8, 1fr); }
    @media only screen and (max-width: 1000px) {
      .col-8 {
        grid-template-columns: 1fr; } }
    .col-8-min {
      display: grid;
      grid-template-columns: repeat(8, -webkit-min-content);
      grid-template-columns: repeat(8, min-content); }
    .col-8-max {
      display: grid;
      grid-template-columns: repeat(8, -webkit-max-content);
      grid-template-columns: repeat(8, max-content); }
  .col-start-8 {
    grid-column-start: 8; }
  .col-end-8 {
    grid-column-end: 9; }
  .col-start-md-8 {
    grid-column-start: 8; }
    @media only screen and (max-width: 750px) {
      .col-start-md-8 {
        grid-column-start: 1; } }
  .col-end-md-8 {
    grid-column-end: 9; }
    @media only screen and (max-width: 750px) {
      .col-end-md-8 {
        grid-column-end: 1; } }
  .col-9 {
    display: grid;
    grid-template-columns: repeat(9, 1fr); }
    @media only screen and (max-width: 1000px) {
      .col-9 {
        grid-template-columns: 1fr; } }
    .col-9-min {
      display: grid;
      grid-template-columns: repeat(9, -webkit-min-content);
      grid-template-columns: repeat(9, min-content); }
    .col-9-max {
      display: grid;
      grid-template-columns: repeat(9, -webkit-max-content);
      grid-template-columns: repeat(9, max-content); }
  .col-start-9 {
    grid-column-start: 9; }
  .col-end-9 {
    grid-column-end: 10; }
  .col-start-md-9 {
    grid-column-start: 9; }
    @media only screen and (max-width: 750px) {
      .col-start-md-9 {
        grid-column-start: 1; } }
  .col-end-md-9 {
    grid-column-end: 10; }
    @media only screen and (max-width: 750px) {
      .col-end-md-9 {
        grid-column-end: 1; } }
  .col-10 {
    display: grid;
    grid-template-columns: repeat(10, 1fr); }
    @media only screen and (max-width: 1000px) {
      .col-10 {
        grid-template-columns: 1fr; } }
    .col-10-min {
      display: grid;
      grid-template-columns: repeat(10, -webkit-min-content);
      grid-template-columns: repeat(10, min-content); }
    .col-10-max {
      display: grid;
      grid-template-columns: repeat(10, -webkit-max-content);
      grid-template-columns: repeat(10, max-content); }
  .col-start-10 {
    grid-column-start: 10; }
  .col-end-10 {
    grid-column-end: 11; }
  .col-start-md-10 {
    grid-column-start: 10; }
    @media only screen and (max-width: 750px) {
      .col-start-md-10 {
        grid-column-start: 1; } }
  .col-end-md-10 {
    grid-column-end: 11; }
    @media only screen and (max-width: 750px) {
      .col-end-md-10 {
        grid-column-end: 1; } }
  .col-11 {
    display: grid;
    grid-template-columns: repeat(11, 1fr); }
    @media only screen and (max-width: 1000px) {
      .col-11 {
        grid-template-columns: 1fr; } }
    .col-11-min {
      display: grid;
      grid-template-columns: repeat(11, -webkit-min-content);
      grid-template-columns: repeat(11, min-content); }
    .col-11-max {
      display: grid;
      grid-template-columns: repeat(11, -webkit-max-content);
      grid-template-columns: repeat(11, max-content); }
  .col-start-11 {
    grid-column-start: 11; }
  .col-end-11 {
    grid-column-end: 12; }
  .col-start-md-11 {
    grid-column-start: 11; }
    @media only screen and (max-width: 750px) {
      .col-start-md-11 {
        grid-column-start: 1; } }
  .col-end-md-11 {
    grid-column-end: 12; }
    @media only screen and (max-width: 750px) {
      .col-end-md-11 {
        grid-column-end: 1; } }
  .col-12 {
    display: grid;
    grid-template-columns: repeat(12, 1fr); }
    @media only screen and (max-width: 1000px) {
      .col-12 {
        grid-template-columns: 1fr; } }
    .col-12-min {
      display: grid;
      grid-template-columns: repeat(12, -webkit-min-content);
      grid-template-columns: repeat(12, min-content); }
    .col-12-max {
      display: grid;
      grid-template-columns: repeat(12, -webkit-max-content);
      grid-template-columns: repeat(12, max-content); }
  .col-start-12 {
    grid-column-start: 12; }
  .col-end-12 {
    grid-column-end: 13; }
  .col-start-md-12 {
    grid-column-start: 12; }
    @media only screen and (max-width: 750px) {
      .col-start-md-12 {
        grid-column-start: 1; } }
  .col-end-md-12 {
    grid-column-end: 13; }
    @media only screen and (max-width: 750px) {
      .col-end-md-12 {
        grid-column-end: 1; } }

.grid {
  display: grid; }
  .grid-center-top {
    display: grid;
    align-items: start;
    justify-content: center; }
  .grid-center-center {
    display: grid;
    align-items: center;
    justify-content: center; }
  .grid-center-bottom {
    display: grid;
    align-items: end;
    justify-content: center; }
  .grid-left-top {
    display: grid;
    align-items: start;
    justify-content: left; }
  .grid-left-center {
    display: grid;
    align-items: center;
    justify-content: left; }
  .grid-left-bottom {
    display: grid;
    align-items: end;
    justify-content: left; }
  .grid-right-top {
    display: grid;
    align-items: start;
    justify-items: right; }
  .grid-right-center {
    display: grid;
    align-items: center;
    justify-items: right; }
  .grid-right-bottom {
    display: grid;
    align-items: end;
    justify-items: right; }

.gap-1 {
  grid-gap: 1px;
  gap: 1px; }

.gap-2 {
  grid-gap: 2px;
  gap: 2px; }

.gap-3 {
  grid-gap: 3px;
  gap: 3px; }

.gap-4 {
  grid-gap: 4px;
  gap: 4px; }

.gap-5 {
  grid-gap: 5px;
  gap: 5px; }

.gap-6 {
  grid-gap: 6px;
  gap: 6px; }

.gap-7 {
  grid-gap: 7px;
  gap: 7px; }

.gap-8 {
  grid-gap: 8px;
  gap: 8px; }

.gap-9 {
  grid-gap: 9px;
  gap: 9px; }

.gap-10 {
  grid-gap: 10px;
  gap: 10px; }

.gap-11 {
  grid-gap: 11px;
  gap: 11px; }

.gap-12 {
  grid-gap: 12px;
  gap: 12px; }

.gap-13 {
  grid-gap: 13px;
  gap: 13px; }

.gap-14 {
  grid-gap: 14px;
  gap: 14px; }

.gap-15 {
  grid-gap: 15px;
  gap: 15px; }

.gap-16 {
  grid-gap: 16px;
  gap: 16px; }

.gap-17 {
  grid-gap: 17px;
  gap: 17px; }

.gap-18 {
  grid-gap: 18px;
  gap: 18px; }

.gap-19 {
  grid-gap: 19px;
  gap: 19px; }

.gap-20 {
  grid-gap: 20px;
  gap: 20px; }

.gap-21 {
  grid-gap: 21px;
  gap: 21px; }

.gap-22 {
  grid-gap: 22px;
  gap: 22px; }

.gap-23 {
  grid-gap: 23px;
  gap: 23px; }

.gap-24 {
  grid-gap: 24px;
  gap: 24px; }

.gap-25 {
  grid-gap: 25px;
  gap: 25px; }

.gap-26 {
  grid-gap: 26px;
  gap: 26px; }

.gap-27 {
  grid-gap: 27px;
  gap: 27px; }

.gap-28 {
  grid-gap: 28px;
  gap: 28px; }

.gap-29 {
  grid-gap: 29px;
  gap: 29px; }

.gap-30 {
  grid-gap: 30px;
  gap: 30px; }

.gap-31 {
  grid-gap: 31px;
  gap: 31px; }

.gap-32 {
  grid-gap: 32px;
  gap: 32px; }

.gap-33 {
  grid-gap: 33px;
  gap: 33px; }

.gap-34 {
  grid-gap: 34px;
  gap: 34px; }

.gap-35 {
  grid-gap: 35px;
  gap: 35px; }

.gap-36 {
  grid-gap: 36px;
  gap: 36px; }

.gap-37 {
  grid-gap: 37px;
  gap: 37px; }

.gap-38 {
  grid-gap: 38px;
  gap: 38px; }

.gap-39 {
  grid-gap: 39px;
  gap: 39px; }

.gap-40 {
  grid-gap: 40px;
  gap: 40px; }

.gap-41 {
  grid-gap: 41px;
  gap: 41px; }

.gap-42 {
  grid-gap: 42px;
  gap: 42px; }

.gap-43 {
  grid-gap: 43px;
  gap: 43px; }

.gap-44 {
  grid-gap: 44px;
  gap: 44px; }

.gap-45 {
  grid-gap: 45px;
  gap: 45px; }

.gap-46 {
  grid-gap: 46px;
  gap: 46px; }

.gap-47 {
  grid-gap: 47px;
  gap: 47px; }

.gap-48 {
  grid-gap: 48px;
  gap: 48px; }

.gap-49 {
  grid-gap: 49px;
  gap: 49px; }

.gap-50 {
  grid-gap: 50px;
  gap: 50px; }

.gap-51 {
  grid-gap: 51px;
  gap: 51px; }

.gap-52 {
  grid-gap: 52px;
  gap: 52px; }

.gap-53 {
  grid-gap: 53px;
  gap: 53px; }

.gap-54 {
  grid-gap: 54px;
  gap: 54px; }

.gap-55 {
  grid-gap: 55px;
  gap: 55px; }

.gap-56 {
  grid-gap: 56px;
  gap: 56px; }

.gap-57 {
  grid-gap: 57px;
  gap: 57px; }

.gap-58 {
  grid-gap: 58px;
  gap: 58px; }

.gap-59 {
  grid-gap: 59px;
  gap: 59px; }

.gap-60 {
  grid-gap: 60px;
  gap: 60px; }

.gap-61 {
  grid-gap: 61px;
  gap: 61px; }

.gap-62 {
  grid-gap: 62px;
  gap: 62px; }

.gap-63 {
  grid-gap: 63px;
  gap: 63px; }

.gap-64 {
  grid-gap: 64px;
  gap: 64px; }

.gap-65 {
  grid-gap: 65px;
  gap: 65px; }

.gap-66 {
  grid-gap: 66px;
  gap: 66px; }

.gap-67 {
  grid-gap: 67px;
  gap: 67px; }

.gap-68 {
  grid-gap: 68px;
  gap: 68px; }

.gap-69 {
  grid-gap: 69px;
  gap: 69px; }

.gap-70 {
  grid-gap: 70px;
  gap: 70px; }

.gap-71 {
  grid-gap: 71px;
  gap: 71px; }

.gap-72 {
  grid-gap: 72px;
  gap: 72px; }

.gap-73 {
  grid-gap: 73px;
  gap: 73px; }

.gap-74 {
  grid-gap: 74px;
  gap: 74px; }

.gap-75 {
  grid-gap: 75px;
  gap: 75px; }

.gap-76 {
  grid-gap: 76px;
  gap: 76px; }

.gap-77 {
  grid-gap: 77px;
  gap: 77px; }

.gap-78 {
  grid-gap: 78px;
  gap: 78px; }

.gap-79 {
  grid-gap: 79px;
  gap: 79px; }

.gap-80 {
  grid-gap: 80px;
  gap: 80px; }

.gap-81 {
  grid-gap: 81px;
  gap: 81px; }

.gap-82 {
  grid-gap: 82px;
  gap: 82px; }

.gap-83 {
  grid-gap: 83px;
  gap: 83px; }

.gap-84 {
  grid-gap: 84px;
  gap: 84px; }

.gap-85 {
  grid-gap: 85px;
  gap: 85px; }

.gap-86 {
  grid-gap: 86px;
  gap: 86px; }

.gap-87 {
  grid-gap: 87px;
  gap: 87px; }

.gap-88 {
  grid-gap: 88px;
  gap: 88px; }

.gap-89 {
  grid-gap: 89px;
  gap: 89px; }

.gap-90 {
  grid-gap: 90px;
  gap: 90px; }

.gap-91 {
  grid-gap: 91px;
  gap: 91px; }

.gap-92 {
  grid-gap: 92px;
  gap: 92px; }

.gap-93 {
  grid-gap: 93px;
  gap: 93px; }

.gap-94 {
  grid-gap: 94px;
  gap: 94px; }

.gap-95 {
  grid-gap: 95px;
  gap: 95px; }

.gap-96 {
  grid-gap: 96px;
  gap: 96px; }

.gap-97 {
  grid-gap: 97px;
  gap: 97px; }

.gap-98 {
  grid-gap: 98px;
  gap: 98px; }

.gap-99 {
  grid-gap: 99px;
  gap: 99px; }

.padding-0 {
  padding: 0px !important; }

.padding-top-0 {
  padding-top: 0px !important; }

.padding-bottom-0 {
  padding-bottom: 0px !important; }

.padding-left-0 {
  padding-left: 0px !important; }

.padding-right-0 {
  padding-right: 0px !important; }

.padding-1 {
  padding: 1px !important; }

.padding-top-1 {
  padding-top: 1px !important; }

.padding-bottom-1 {
  padding-bottom: 1px !important; }

.padding-left-1 {
  padding-left: 1px !important; }

.padding-right-1 {
  padding-right: 1px !important; }

.padding-2 {
  padding: 2px !important; }

.padding-top-2 {
  padding-top: 2px !important; }

.padding-bottom-2 {
  padding-bottom: 2px !important; }

.padding-left-2 {
  padding-left: 2px !important; }

.padding-right-2 {
  padding-right: 2px !important; }

.padding-3 {
  padding: 3px !important; }

.padding-top-3 {
  padding-top: 3px !important; }

.padding-bottom-3 {
  padding-bottom: 3px !important; }

.padding-left-3 {
  padding-left: 3px !important; }

.padding-right-3 {
  padding-right: 3px !important; }

.padding-4 {
  padding: 4px !important; }

.padding-top-4 {
  padding-top: 4px !important; }

.padding-bottom-4 {
  padding-bottom: 4px !important; }

.padding-left-4 {
  padding-left: 4px !important; }

.padding-right-4 {
  padding-right: 4px !important; }

.padding-5 {
  padding: 5px !important; }

.padding-top-5 {
  padding-top: 5px !important; }

.padding-bottom-5 {
  padding-bottom: 5px !important; }

.padding-left-5 {
  padding-left: 5px !important; }

.padding-right-5 {
  padding-right: 5px !important; }

.padding-6 {
  padding: 6px !important; }

.padding-top-6 {
  padding-top: 6px !important; }

.padding-bottom-6 {
  padding-bottom: 6px !important; }

.padding-left-6 {
  padding-left: 6px !important; }

.padding-right-6 {
  padding-right: 6px !important; }

.padding-7 {
  padding: 7px !important; }

.padding-top-7 {
  padding-top: 7px !important; }

.padding-bottom-7 {
  padding-bottom: 7px !important; }

.padding-left-7 {
  padding-left: 7px !important; }

.padding-right-7 {
  padding-right: 7px !important; }

.padding-8 {
  padding: 8px !important; }

.padding-top-8 {
  padding-top: 8px !important; }

.padding-bottom-8 {
  padding-bottom: 8px !important; }

.padding-left-8 {
  padding-left: 8px !important; }

.padding-right-8 {
  padding-right: 8px !important; }

.padding-9 {
  padding: 9px !important; }

.padding-top-9 {
  padding-top: 9px !important; }

.padding-bottom-9 {
  padding-bottom: 9px !important; }

.padding-left-9 {
  padding-left: 9px !important; }

.padding-right-9 {
  padding-right: 9px !important; }

.padding-10 {
  padding: 10px !important; }

.padding-top-10 {
  padding-top: 10px !important; }

.padding-bottom-10 {
  padding-bottom: 10px !important; }

.padding-left-10 {
  padding-left: 10px !important; }

.padding-right-10 {
  padding-right: 10px !important; }

.padding-11 {
  padding: 11px !important; }

.padding-top-11 {
  padding-top: 11px !important; }

.padding-bottom-11 {
  padding-bottom: 11px !important; }

.padding-left-11 {
  padding-left: 11px !important; }

.padding-right-11 {
  padding-right: 11px !important; }

.padding-12 {
  padding: 12px !important; }

.padding-top-12 {
  padding-top: 12px !important; }

.padding-bottom-12 {
  padding-bottom: 12px !important; }

.padding-left-12 {
  padding-left: 12px !important; }

.padding-right-12 {
  padding-right: 12px !important; }

.padding-13 {
  padding: 13px !important; }

.padding-top-13 {
  padding-top: 13px !important; }

.padding-bottom-13 {
  padding-bottom: 13px !important; }

.padding-left-13 {
  padding-left: 13px !important; }

.padding-right-13 {
  padding-right: 13px !important; }

.padding-14 {
  padding: 14px !important; }

.padding-top-14 {
  padding-top: 14px !important; }

.padding-bottom-14 {
  padding-bottom: 14px !important; }

.padding-left-14 {
  padding-left: 14px !important; }

.padding-right-14 {
  padding-right: 14px !important; }

.padding-15 {
  padding: 15px !important; }

.padding-top-15 {
  padding-top: 15px !important; }

.padding-bottom-15 {
  padding-bottom: 15px !important; }

.padding-left-15 {
  padding-left: 15px !important; }

.padding-right-15 {
  padding-right: 15px !important; }

.padding-16 {
  padding: 16px !important; }

.padding-top-16 {
  padding-top: 16px !important; }

.padding-bottom-16 {
  padding-bottom: 16px !important; }

.padding-left-16 {
  padding-left: 16px !important; }

.padding-right-16 {
  padding-right: 16px !important; }

.padding-17 {
  padding: 17px !important; }

.padding-top-17 {
  padding-top: 17px !important; }

.padding-bottom-17 {
  padding-bottom: 17px !important; }

.padding-left-17 {
  padding-left: 17px !important; }

.padding-right-17 {
  padding-right: 17px !important; }

.padding-18 {
  padding: 18px !important; }

.padding-top-18 {
  padding-top: 18px !important; }

.padding-bottom-18 {
  padding-bottom: 18px !important; }

.padding-left-18 {
  padding-left: 18px !important; }

.padding-right-18 {
  padding-right: 18px !important; }

.padding-19 {
  padding: 19px !important; }

.padding-top-19 {
  padding-top: 19px !important; }

.padding-bottom-19 {
  padding-bottom: 19px !important; }

.padding-left-19 {
  padding-left: 19px !important; }

.padding-right-19 {
  padding-right: 19px !important; }

.padding-20 {
  padding: 20px !important; }

.padding-top-20 {
  padding-top: 20px !important; }

.padding-bottom-20 {
  padding-bottom: 20px !important; }

.padding-left-20 {
  padding-left: 20px !important; }

.padding-right-20 {
  padding-right: 20px !important; }

.padding-21 {
  padding: 21px !important; }

.padding-top-21 {
  padding-top: 21px !important; }

.padding-bottom-21 {
  padding-bottom: 21px !important; }

.padding-left-21 {
  padding-left: 21px !important; }

.padding-right-21 {
  padding-right: 21px !important; }

.padding-22 {
  padding: 22px !important; }

.padding-top-22 {
  padding-top: 22px !important; }

.padding-bottom-22 {
  padding-bottom: 22px !important; }

.padding-left-22 {
  padding-left: 22px !important; }

.padding-right-22 {
  padding-right: 22px !important; }

.padding-23 {
  padding: 23px !important; }

.padding-top-23 {
  padding-top: 23px !important; }

.padding-bottom-23 {
  padding-bottom: 23px !important; }

.padding-left-23 {
  padding-left: 23px !important; }

.padding-right-23 {
  padding-right: 23px !important; }

.padding-24 {
  padding: 24px !important; }

.padding-top-24 {
  padding-top: 24px !important; }

.padding-bottom-24 {
  padding-bottom: 24px !important; }

.padding-left-24 {
  padding-left: 24px !important; }

.padding-right-24 {
  padding-right: 24px !important; }

.padding-25 {
  padding: 25px !important; }

.padding-top-25 {
  padding-top: 25px !important; }

.padding-bottom-25 {
  padding-bottom: 25px !important; }

.padding-left-25 {
  padding-left: 25px !important; }

.padding-right-25 {
  padding-right: 25px !important; }

.padding-26 {
  padding: 26px !important; }

.padding-top-26 {
  padding-top: 26px !important; }

.padding-bottom-26 {
  padding-bottom: 26px !important; }

.padding-left-26 {
  padding-left: 26px !important; }

.padding-right-26 {
  padding-right: 26px !important; }

.padding-27 {
  padding: 27px !important; }

.padding-top-27 {
  padding-top: 27px !important; }

.padding-bottom-27 {
  padding-bottom: 27px !important; }

.padding-left-27 {
  padding-left: 27px !important; }

.padding-right-27 {
  padding-right: 27px !important; }

.padding-28 {
  padding: 28px !important; }

.padding-top-28 {
  padding-top: 28px !important; }

.padding-bottom-28 {
  padding-bottom: 28px !important; }

.padding-left-28 {
  padding-left: 28px !important; }

.padding-right-28 {
  padding-right: 28px !important; }

.padding-29 {
  padding: 29px !important; }

.padding-top-29 {
  padding-top: 29px !important; }

.padding-bottom-29 {
  padding-bottom: 29px !important; }

.padding-left-29 {
  padding-left: 29px !important; }

.padding-right-29 {
  padding-right: 29px !important; }

.padding-30 {
  padding: 30px !important; }

.padding-top-30 {
  padding-top: 30px !important; }

.padding-bottom-30 {
  padding-bottom: 30px !important; }

.padding-left-30 {
  padding-left: 30px !important; }

.padding-right-30 {
  padding-right: 30px !important; }

.padding-31 {
  padding: 31px !important; }

.padding-top-31 {
  padding-top: 31px !important; }

.padding-bottom-31 {
  padding-bottom: 31px !important; }

.padding-left-31 {
  padding-left: 31px !important; }

.padding-right-31 {
  padding-right: 31px !important; }

.padding-32 {
  padding: 32px !important; }

.padding-top-32 {
  padding-top: 32px !important; }

.padding-bottom-32 {
  padding-bottom: 32px !important; }

.padding-left-32 {
  padding-left: 32px !important; }

.padding-right-32 {
  padding-right: 32px !important; }

.padding-33 {
  padding: 33px !important; }

.padding-top-33 {
  padding-top: 33px !important; }

.padding-bottom-33 {
  padding-bottom: 33px !important; }

.padding-left-33 {
  padding-left: 33px !important; }

.padding-right-33 {
  padding-right: 33px !important; }

.padding-34 {
  padding: 34px !important; }

.padding-top-34 {
  padding-top: 34px !important; }

.padding-bottom-34 {
  padding-bottom: 34px !important; }

.padding-left-34 {
  padding-left: 34px !important; }

.padding-right-34 {
  padding-right: 34px !important; }

.padding-35 {
  padding: 35px !important; }

.padding-top-35 {
  padding-top: 35px !important; }

.padding-bottom-35 {
  padding-bottom: 35px !important; }

.padding-left-35 {
  padding-left: 35px !important; }

.padding-right-35 {
  padding-right: 35px !important; }

.padding-36 {
  padding: 36px !important; }

.padding-top-36 {
  padding-top: 36px !important; }

.padding-bottom-36 {
  padding-bottom: 36px !important; }

.padding-left-36 {
  padding-left: 36px !important; }

.padding-right-36 {
  padding-right: 36px !important; }

.padding-37 {
  padding: 37px !important; }

.padding-top-37 {
  padding-top: 37px !important; }

.padding-bottom-37 {
  padding-bottom: 37px !important; }

.padding-left-37 {
  padding-left: 37px !important; }

.padding-right-37 {
  padding-right: 37px !important; }

.padding-38 {
  padding: 38px !important; }

.padding-top-38 {
  padding-top: 38px !important; }

.padding-bottom-38 {
  padding-bottom: 38px !important; }

.padding-left-38 {
  padding-left: 38px !important; }

.padding-right-38 {
  padding-right: 38px !important; }

.padding-39 {
  padding: 39px !important; }

.padding-top-39 {
  padding-top: 39px !important; }

.padding-bottom-39 {
  padding-bottom: 39px !important; }

.padding-left-39 {
  padding-left: 39px !important; }

.padding-right-39 {
  padding-right: 39px !important; }

.padding-40 {
  padding: 40px !important; }

.padding-top-40 {
  padding-top: 40px !important; }

.padding-bottom-40 {
  padding-bottom: 40px !important; }

.padding-left-40 {
  padding-left: 40px !important; }

.padding-right-40 {
  padding-right: 40px !important; }

.padding-41 {
  padding: 41px !important; }

.padding-top-41 {
  padding-top: 41px !important; }

.padding-bottom-41 {
  padding-bottom: 41px !important; }

.padding-left-41 {
  padding-left: 41px !important; }

.padding-right-41 {
  padding-right: 41px !important; }

.padding-42 {
  padding: 42px !important; }

.padding-top-42 {
  padding-top: 42px !important; }

.padding-bottom-42 {
  padding-bottom: 42px !important; }

.padding-left-42 {
  padding-left: 42px !important; }

.padding-right-42 {
  padding-right: 42px !important; }

.padding-43 {
  padding: 43px !important; }

.padding-top-43 {
  padding-top: 43px !important; }

.padding-bottom-43 {
  padding-bottom: 43px !important; }

.padding-left-43 {
  padding-left: 43px !important; }

.padding-right-43 {
  padding-right: 43px !important; }

.padding-44 {
  padding: 44px !important; }

.padding-top-44 {
  padding-top: 44px !important; }

.padding-bottom-44 {
  padding-bottom: 44px !important; }

.padding-left-44 {
  padding-left: 44px !important; }

.padding-right-44 {
  padding-right: 44px !important; }

.padding-45 {
  padding: 45px !important; }

.padding-top-45 {
  padding-top: 45px !important; }

.padding-bottom-45 {
  padding-bottom: 45px !important; }

.padding-left-45 {
  padding-left: 45px !important; }

.padding-right-45 {
  padding-right: 45px !important; }

.padding-46 {
  padding: 46px !important; }

.padding-top-46 {
  padding-top: 46px !important; }

.padding-bottom-46 {
  padding-bottom: 46px !important; }

.padding-left-46 {
  padding-left: 46px !important; }

.padding-right-46 {
  padding-right: 46px !important; }

.padding-47 {
  padding: 47px !important; }

.padding-top-47 {
  padding-top: 47px !important; }

.padding-bottom-47 {
  padding-bottom: 47px !important; }

.padding-left-47 {
  padding-left: 47px !important; }

.padding-right-47 {
  padding-right: 47px !important; }

.padding-48 {
  padding: 48px !important; }

.padding-top-48 {
  padding-top: 48px !important; }

.padding-bottom-48 {
  padding-bottom: 48px !important; }

.padding-left-48 {
  padding-left: 48px !important; }

.padding-right-48 {
  padding-right: 48px !important; }

.padding-49 {
  padding: 49px !important; }

.padding-top-49 {
  padding-top: 49px !important; }

.padding-bottom-49 {
  padding-bottom: 49px !important; }

.padding-left-49 {
  padding-left: 49px !important; }

.padding-right-49 {
  padding-right: 49px !important; }

.padding-50 {
  padding: 50px !important; }

.padding-top-50 {
  padding-top: 50px !important; }

.padding-bottom-50 {
  padding-bottom: 50px !important; }

.padding-left-50 {
  padding-left: 50px !important; }

.padding-right-50 {
  padding-right: 50px !important; }

.padding-51 {
  padding: 51px !important; }

.padding-top-51 {
  padding-top: 51px !important; }

.padding-bottom-51 {
  padding-bottom: 51px !important; }

.padding-left-51 {
  padding-left: 51px !important; }

.padding-right-51 {
  padding-right: 51px !important; }

.padding-52 {
  padding: 52px !important; }

.padding-top-52 {
  padding-top: 52px !important; }

.padding-bottom-52 {
  padding-bottom: 52px !important; }

.padding-left-52 {
  padding-left: 52px !important; }

.padding-right-52 {
  padding-right: 52px !important; }

.padding-53 {
  padding: 53px !important; }

.padding-top-53 {
  padding-top: 53px !important; }

.padding-bottom-53 {
  padding-bottom: 53px !important; }

.padding-left-53 {
  padding-left: 53px !important; }

.padding-right-53 {
  padding-right: 53px !important; }

.padding-54 {
  padding: 54px !important; }

.padding-top-54 {
  padding-top: 54px !important; }

.padding-bottom-54 {
  padding-bottom: 54px !important; }

.padding-left-54 {
  padding-left: 54px !important; }

.padding-right-54 {
  padding-right: 54px !important; }

.padding-55 {
  padding: 55px !important; }

.padding-top-55 {
  padding-top: 55px !important; }

.padding-bottom-55 {
  padding-bottom: 55px !important; }

.padding-left-55 {
  padding-left: 55px !important; }

.padding-right-55 {
  padding-right: 55px !important; }

.padding-56 {
  padding: 56px !important; }

.padding-top-56 {
  padding-top: 56px !important; }

.padding-bottom-56 {
  padding-bottom: 56px !important; }

.padding-left-56 {
  padding-left: 56px !important; }

.padding-right-56 {
  padding-right: 56px !important; }

.padding-57 {
  padding: 57px !important; }

.padding-top-57 {
  padding-top: 57px !important; }

.padding-bottom-57 {
  padding-bottom: 57px !important; }

.padding-left-57 {
  padding-left: 57px !important; }

.padding-right-57 {
  padding-right: 57px !important; }

.padding-58 {
  padding: 58px !important; }

.padding-top-58 {
  padding-top: 58px !important; }

.padding-bottom-58 {
  padding-bottom: 58px !important; }

.padding-left-58 {
  padding-left: 58px !important; }

.padding-right-58 {
  padding-right: 58px !important; }

.padding-59 {
  padding: 59px !important; }

.padding-top-59 {
  padding-top: 59px !important; }

.padding-bottom-59 {
  padding-bottom: 59px !important; }

.padding-left-59 {
  padding-left: 59px !important; }

.padding-right-59 {
  padding-right: 59px !important; }

.padding-60 {
  padding: 60px !important; }

.padding-top-60 {
  padding-top: 60px !important; }

.padding-bottom-60 {
  padding-bottom: 60px !important; }

.padding-left-60 {
  padding-left: 60px !important; }

.padding-right-60 {
  padding-right: 60px !important; }

.padding-61 {
  padding: 61px !important; }

.padding-top-61 {
  padding-top: 61px !important; }

.padding-bottom-61 {
  padding-bottom: 61px !important; }

.padding-left-61 {
  padding-left: 61px !important; }

.padding-right-61 {
  padding-right: 61px !important; }

.padding-62 {
  padding: 62px !important; }

.padding-top-62 {
  padding-top: 62px !important; }

.padding-bottom-62 {
  padding-bottom: 62px !important; }

.padding-left-62 {
  padding-left: 62px !important; }

.padding-right-62 {
  padding-right: 62px !important; }

.padding-63 {
  padding: 63px !important; }

.padding-top-63 {
  padding-top: 63px !important; }

.padding-bottom-63 {
  padding-bottom: 63px !important; }

.padding-left-63 {
  padding-left: 63px !important; }

.padding-right-63 {
  padding-right: 63px !important; }

.padding-64 {
  padding: 64px !important; }

.padding-top-64 {
  padding-top: 64px !important; }

.padding-bottom-64 {
  padding-bottom: 64px !important; }

.padding-left-64 {
  padding-left: 64px !important; }

.padding-right-64 {
  padding-right: 64px !important; }

.padding-65 {
  padding: 65px !important; }

.padding-top-65 {
  padding-top: 65px !important; }

.padding-bottom-65 {
  padding-bottom: 65px !important; }

.padding-left-65 {
  padding-left: 65px !important; }

.padding-right-65 {
  padding-right: 65px !important; }

.padding-66 {
  padding: 66px !important; }

.padding-top-66 {
  padding-top: 66px !important; }

.padding-bottom-66 {
  padding-bottom: 66px !important; }

.padding-left-66 {
  padding-left: 66px !important; }

.padding-right-66 {
  padding-right: 66px !important; }

.padding-67 {
  padding: 67px !important; }

.padding-top-67 {
  padding-top: 67px !important; }

.padding-bottom-67 {
  padding-bottom: 67px !important; }

.padding-left-67 {
  padding-left: 67px !important; }

.padding-right-67 {
  padding-right: 67px !important; }

.padding-68 {
  padding: 68px !important; }

.padding-top-68 {
  padding-top: 68px !important; }

.padding-bottom-68 {
  padding-bottom: 68px !important; }

.padding-left-68 {
  padding-left: 68px !important; }

.padding-right-68 {
  padding-right: 68px !important; }

.padding-69 {
  padding: 69px !important; }

.padding-top-69 {
  padding-top: 69px !important; }

.padding-bottom-69 {
  padding-bottom: 69px !important; }

.padding-left-69 {
  padding-left: 69px !important; }

.padding-right-69 {
  padding-right: 69px !important; }

.padding-70 {
  padding: 70px !important; }

.padding-top-70 {
  padding-top: 70px !important; }

.padding-bottom-70 {
  padding-bottom: 70px !important; }

.padding-left-70 {
  padding-left: 70px !important; }

.padding-right-70 {
  padding-right: 70px !important; }

.padding-71 {
  padding: 71px !important; }

.padding-top-71 {
  padding-top: 71px !important; }

.padding-bottom-71 {
  padding-bottom: 71px !important; }

.padding-left-71 {
  padding-left: 71px !important; }

.padding-right-71 {
  padding-right: 71px !important; }

.padding-72 {
  padding: 72px !important; }

.padding-top-72 {
  padding-top: 72px !important; }

.padding-bottom-72 {
  padding-bottom: 72px !important; }

.padding-left-72 {
  padding-left: 72px !important; }

.padding-right-72 {
  padding-right: 72px !important; }

.padding-73 {
  padding: 73px !important; }

.padding-top-73 {
  padding-top: 73px !important; }

.padding-bottom-73 {
  padding-bottom: 73px !important; }

.padding-left-73 {
  padding-left: 73px !important; }

.padding-right-73 {
  padding-right: 73px !important; }

.padding-74 {
  padding: 74px !important; }

.padding-top-74 {
  padding-top: 74px !important; }

.padding-bottom-74 {
  padding-bottom: 74px !important; }

.padding-left-74 {
  padding-left: 74px !important; }

.padding-right-74 {
  padding-right: 74px !important; }

.padding-75 {
  padding: 75px !important; }

.padding-top-75 {
  padding-top: 75px !important; }

.padding-bottom-75 {
  padding-bottom: 75px !important; }

.padding-left-75 {
  padding-left: 75px !important; }

.padding-right-75 {
  padding-right: 75px !important; }

.padding-76 {
  padding: 76px !important; }

.padding-top-76 {
  padding-top: 76px !important; }

.padding-bottom-76 {
  padding-bottom: 76px !important; }

.padding-left-76 {
  padding-left: 76px !important; }

.padding-right-76 {
  padding-right: 76px !important; }

.padding-77 {
  padding: 77px !important; }

.padding-top-77 {
  padding-top: 77px !important; }

.padding-bottom-77 {
  padding-bottom: 77px !important; }

.padding-left-77 {
  padding-left: 77px !important; }

.padding-right-77 {
  padding-right: 77px !important; }

.padding-78 {
  padding: 78px !important; }

.padding-top-78 {
  padding-top: 78px !important; }

.padding-bottom-78 {
  padding-bottom: 78px !important; }

.padding-left-78 {
  padding-left: 78px !important; }

.padding-right-78 {
  padding-right: 78px !important; }

.padding-79 {
  padding: 79px !important; }

.padding-top-79 {
  padding-top: 79px !important; }

.padding-bottom-79 {
  padding-bottom: 79px !important; }

.padding-left-79 {
  padding-left: 79px !important; }

.padding-right-79 {
  padding-right: 79px !important; }

.padding-80 {
  padding: 80px !important; }

.padding-top-80 {
  padding-top: 80px !important; }

.padding-bottom-80 {
  padding-bottom: 80px !important; }

.padding-left-80 {
  padding-left: 80px !important; }

.padding-right-80 {
  padding-right: 80px !important; }

.padding-81 {
  padding: 81px !important; }

.padding-top-81 {
  padding-top: 81px !important; }

.padding-bottom-81 {
  padding-bottom: 81px !important; }

.padding-left-81 {
  padding-left: 81px !important; }

.padding-right-81 {
  padding-right: 81px !important; }

.padding-82 {
  padding: 82px !important; }

.padding-top-82 {
  padding-top: 82px !important; }

.padding-bottom-82 {
  padding-bottom: 82px !important; }

.padding-left-82 {
  padding-left: 82px !important; }

.padding-right-82 {
  padding-right: 82px !important; }

.padding-83 {
  padding: 83px !important; }

.padding-top-83 {
  padding-top: 83px !important; }

.padding-bottom-83 {
  padding-bottom: 83px !important; }

.padding-left-83 {
  padding-left: 83px !important; }

.padding-right-83 {
  padding-right: 83px !important; }

.padding-84 {
  padding: 84px !important; }

.padding-top-84 {
  padding-top: 84px !important; }

.padding-bottom-84 {
  padding-bottom: 84px !important; }

.padding-left-84 {
  padding-left: 84px !important; }

.padding-right-84 {
  padding-right: 84px !important; }

.padding-85 {
  padding: 85px !important; }

.padding-top-85 {
  padding-top: 85px !important; }

.padding-bottom-85 {
  padding-bottom: 85px !important; }

.padding-left-85 {
  padding-left: 85px !important; }

.padding-right-85 {
  padding-right: 85px !important; }

.padding-86 {
  padding: 86px !important; }

.padding-top-86 {
  padding-top: 86px !important; }

.padding-bottom-86 {
  padding-bottom: 86px !important; }

.padding-left-86 {
  padding-left: 86px !important; }

.padding-right-86 {
  padding-right: 86px !important; }

.padding-87 {
  padding: 87px !important; }

.padding-top-87 {
  padding-top: 87px !important; }

.padding-bottom-87 {
  padding-bottom: 87px !important; }

.padding-left-87 {
  padding-left: 87px !important; }

.padding-right-87 {
  padding-right: 87px !important; }

.padding-88 {
  padding: 88px !important; }

.padding-top-88 {
  padding-top: 88px !important; }

.padding-bottom-88 {
  padding-bottom: 88px !important; }

.padding-left-88 {
  padding-left: 88px !important; }

.padding-right-88 {
  padding-right: 88px !important; }

.padding-89 {
  padding: 89px !important; }

.padding-top-89 {
  padding-top: 89px !important; }

.padding-bottom-89 {
  padding-bottom: 89px !important; }

.padding-left-89 {
  padding-left: 89px !important; }

.padding-right-89 {
  padding-right: 89px !important; }

.padding-90 {
  padding: 90px !important; }

.padding-top-90 {
  padding-top: 90px !important; }

.padding-bottom-90 {
  padding-bottom: 90px !important; }

.padding-left-90 {
  padding-left: 90px !important; }

.padding-right-90 {
  padding-right: 90px !important; }

.padding-91 {
  padding: 91px !important; }

.padding-top-91 {
  padding-top: 91px !important; }

.padding-bottom-91 {
  padding-bottom: 91px !important; }

.padding-left-91 {
  padding-left: 91px !important; }

.padding-right-91 {
  padding-right: 91px !important; }

.padding-92 {
  padding: 92px !important; }

.padding-top-92 {
  padding-top: 92px !important; }

.padding-bottom-92 {
  padding-bottom: 92px !important; }

.padding-left-92 {
  padding-left: 92px !important; }

.padding-right-92 {
  padding-right: 92px !important; }

.padding-93 {
  padding: 93px !important; }

.padding-top-93 {
  padding-top: 93px !important; }

.padding-bottom-93 {
  padding-bottom: 93px !important; }

.padding-left-93 {
  padding-left: 93px !important; }

.padding-right-93 {
  padding-right: 93px !important; }

.padding-94 {
  padding: 94px !important; }

.padding-top-94 {
  padding-top: 94px !important; }

.padding-bottom-94 {
  padding-bottom: 94px !important; }

.padding-left-94 {
  padding-left: 94px !important; }

.padding-right-94 {
  padding-right: 94px !important; }

.padding-95 {
  padding: 95px !important; }

.padding-top-95 {
  padding-top: 95px !important; }

.padding-bottom-95 {
  padding-bottom: 95px !important; }

.padding-left-95 {
  padding-left: 95px !important; }

.padding-right-95 {
  padding-right: 95px !important; }

.padding-96 {
  padding: 96px !important; }

.padding-top-96 {
  padding-top: 96px !important; }

.padding-bottom-96 {
  padding-bottom: 96px !important; }

.padding-left-96 {
  padding-left: 96px !important; }

.padding-right-96 {
  padding-right: 96px !important; }

.padding-97 {
  padding: 97px !important; }

.padding-top-97 {
  padding-top: 97px !important; }

.padding-bottom-97 {
  padding-bottom: 97px !important; }

.padding-left-97 {
  padding-left: 97px !important; }

.padding-right-97 {
  padding-right: 97px !important; }

.padding-98 {
  padding: 98px !important; }

.padding-top-98 {
  padding-top: 98px !important; }

.padding-bottom-98 {
  padding-bottom: 98px !important; }

.padding-left-98 {
  padding-left: 98px !important; }

.padding-right-98 {
  padding-right: 98px !important; }

.padding-99 {
  padding: 99px !important; }

.padding-top-99 {
  padding-top: 99px !important; }

.padding-bottom-99 {
  padding-bottom: 99px !important; }

.padding-left-99 {
  padding-left: 99px !important; }

.padding-right-99 {
  padding-right: 99px !important; }

.margin-0 {
  margin: 0px !important; }

.margin-top-0 {
  margin-top: 0px !important; }

.margin-bottom-0 {
  margin-bottom: 0px !important; }

.margin-left-0 {
  margin-left: 0px !important; }

.margin-right-0 {
  margin-right: 0px !important; }

.margin-1 {
  margin: 1px !important; }

.margin-top-1 {
  margin-top: 1px !important; }

.margin-bottom-1 {
  margin-bottom: 1px !important; }

.margin-left-1 {
  margin-left: 1px !important; }

.margin-right-1 {
  margin-right: 1px !important; }

.margin-2 {
  margin: 2px !important; }

.margin-top-2 {
  margin-top: 2px !important; }

.margin-bottom-2 {
  margin-bottom: 2px !important; }

.margin-left-2 {
  margin-left: 2px !important; }

.margin-right-2 {
  margin-right: 2px !important; }

.margin-3 {
  margin: 3px !important; }

.margin-top-3 {
  margin-top: 3px !important; }

.margin-bottom-3 {
  margin-bottom: 3px !important; }

.margin-left-3 {
  margin-left: 3px !important; }

.margin-right-3 {
  margin-right: 3px !important; }

.margin-4 {
  margin: 4px !important; }

.margin-top-4 {
  margin-top: 4px !important; }

.margin-bottom-4 {
  margin-bottom: 4px !important; }

.margin-left-4 {
  margin-left: 4px !important; }

.margin-right-4 {
  margin-right: 4px !important; }

.margin-5 {
  margin: 5px !important; }

.margin-top-5 {
  margin-top: 5px !important; }

.margin-bottom-5 {
  margin-bottom: 5px !important; }

.margin-left-5 {
  margin-left: 5px !important; }

.margin-right-5 {
  margin-right: 5px !important; }

.margin-6 {
  margin: 6px !important; }

.margin-top-6 {
  margin-top: 6px !important; }

.margin-bottom-6 {
  margin-bottom: 6px !important; }

.margin-left-6 {
  margin-left: 6px !important; }

.margin-right-6 {
  margin-right: 6px !important; }

.margin-7 {
  margin: 7px !important; }

.margin-top-7 {
  margin-top: 7px !important; }

.margin-bottom-7 {
  margin-bottom: 7px !important; }

.margin-left-7 {
  margin-left: 7px !important; }

.margin-right-7 {
  margin-right: 7px !important; }

.margin-8 {
  margin: 8px !important; }

.margin-top-8 {
  margin-top: 8px !important; }

.margin-bottom-8 {
  margin-bottom: 8px !important; }

.margin-left-8 {
  margin-left: 8px !important; }

.margin-right-8 {
  margin-right: 8px !important; }

.margin-9 {
  margin: 9px !important; }

.margin-top-9 {
  margin-top: 9px !important; }

.margin-bottom-9 {
  margin-bottom: 9px !important; }

.margin-left-9 {
  margin-left: 9px !important; }

.margin-right-9 {
  margin-right: 9px !important; }

.margin-10 {
  margin: 10px !important; }

.margin-top-10 {
  margin-top: 10px !important; }

.margin-bottom-10 {
  margin-bottom: 10px !important; }

.margin-left-10 {
  margin-left: 10px !important; }

.margin-right-10 {
  margin-right: 10px !important; }

.margin-11 {
  margin: 11px !important; }

.margin-top-11 {
  margin-top: 11px !important; }

.margin-bottom-11 {
  margin-bottom: 11px !important; }

.margin-left-11 {
  margin-left: 11px !important; }

.margin-right-11 {
  margin-right: 11px !important; }

.margin-12 {
  margin: 12px !important; }

.margin-top-12 {
  margin-top: 12px !important; }

.margin-bottom-12 {
  margin-bottom: 12px !important; }

.margin-left-12 {
  margin-left: 12px !important; }

.margin-right-12 {
  margin-right: 12px !important; }

.margin-13 {
  margin: 13px !important; }

.margin-top-13 {
  margin-top: 13px !important; }

.margin-bottom-13 {
  margin-bottom: 13px !important; }

.margin-left-13 {
  margin-left: 13px !important; }

.margin-right-13 {
  margin-right: 13px !important; }

.margin-14 {
  margin: 14px !important; }

.margin-top-14 {
  margin-top: 14px !important; }

.margin-bottom-14 {
  margin-bottom: 14px !important; }

.margin-left-14 {
  margin-left: 14px !important; }

.margin-right-14 {
  margin-right: 14px !important; }

.margin-15 {
  margin: 15px !important; }

.margin-top-15 {
  margin-top: 15px !important; }

.margin-bottom-15 {
  margin-bottom: 15px !important; }

.margin-left-15 {
  margin-left: 15px !important; }

.margin-right-15 {
  margin-right: 15px !important; }

.margin-16 {
  margin: 16px !important; }

.margin-top-16 {
  margin-top: 16px !important; }

.margin-bottom-16 {
  margin-bottom: 16px !important; }

.margin-left-16 {
  margin-left: 16px !important; }

.margin-right-16 {
  margin-right: 16px !important; }

.margin-17 {
  margin: 17px !important; }

.margin-top-17 {
  margin-top: 17px !important; }

.margin-bottom-17 {
  margin-bottom: 17px !important; }

.margin-left-17 {
  margin-left: 17px !important; }

.margin-right-17 {
  margin-right: 17px !important; }

.margin-18 {
  margin: 18px !important; }

.margin-top-18 {
  margin-top: 18px !important; }

.margin-bottom-18 {
  margin-bottom: 18px !important; }

.margin-left-18 {
  margin-left: 18px !important; }

.margin-right-18 {
  margin-right: 18px !important; }

.margin-19 {
  margin: 19px !important; }

.margin-top-19 {
  margin-top: 19px !important; }

.margin-bottom-19 {
  margin-bottom: 19px !important; }

.margin-left-19 {
  margin-left: 19px !important; }

.margin-right-19 {
  margin-right: 19px !important; }

.margin-20 {
  margin: 20px !important; }

.margin-top-20 {
  margin-top: 20px !important; }

.margin-bottom-20 {
  margin-bottom: 20px !important; }

.margin-left-20 {
  margin-left: 20px !important; }

.margin-right-20 {
  margin-right: 20px !important; }

.margin-21 {
  margin: 21px !important; }

.margin-top-21 {
  margin-top: 21px !important; }

.margin-bottom-21 {
  margin-bottom: 21px !important; }

.margin-left-21 {
  margin-left: 21px !important; }

.margin-right-21 {
  margin-right: 21px !important; }

.margin-22 {
  margin: 22px !important; }

.margin-top-22 {
  margin-top: 22px !important; }

.margin-bottom-22 {
  margin-bottom: 22px !important; }

.margin-left-22 {
  margin-left: 22px !important; }

.margin-right-22 {
  margin-right: 22px !important; }

.margin-23 {
  margin: 23px !important; }

.margin-top-23 {
  margin-top: 23px !important; }

.margin-bottom-23 {
  margin-bottom: 23px !important; }

.margin-left-23 {
  margin-left: 23px !important; }

.margin-right-23 {
  margin-right: 23px !important; }

.margin-24 {
  margin: 24px !important; }

.margin-top-24 {
  margin-top: 24px !important; }

.margin-bottom-24 {
  margin-bottom: 24px !important; }

.margin-left-24 {
  margin-left: 24px !important; }

.margin-right-24 {
  margin-right: 24px !important; }

.margin-25 {
  margin: 25px !important; }

.margin-top-25 {
  margin-top: 25px !important; }

.margin-bottom-25 {
  margin-bottom: 25px !important; }

.margin-left-25 {
  margin-left: 25px !important; }

.margin-right-25 {
  margin-right: 25px !important; }

.margin-26 {
  margin: 26px !important; }

.margin-top-26 {
  margin-top: 26px !important; }

.margin-bottom-26 {
  margin-bottom: 26px !important; }

.margin-left-26 {
  margin-left: 26px !important; }

.margin-right-26 {
  margin-right: 26px !important; }

.margin-27 {
  margin: 27px !important; }

.margin-top-27 {
  margin-top: 27px !important; }

.margin-bottom-27 {
  margin-bottom: 27px !important; }

.margin-left-27 {
  margin-left: 27px !important; }

.margin-right-27 {
  margin-right: 27px !important; }

.margin-28 {
  margin: 28px !important; }

.margin-top-28 {
  margin-top: 28px !important; }

.margin-bottom-28 {
  margin-bottom: 28px !important; }

.margin-left-28 {
  margin-left: 28px !important; }

.margin-right-28 {
  margin-right: 28px !important; }

.margin-29 {
  margin: 29px !important; }

.margin-top-29 {
  margin-top: 29px !important; }

.margin-bottom-29 {
  margin-bottom: 29px !important; }

.margin-left-29 {
  margin-left: 29px !important; }

.margin-right-29 {
  margin-right: 29px !important; }

.margin-30 {
  margin: 30px !important; }

.margin-top-30 {
  margin-top: 30px !important; }

.margin-bottom-30 {
  margin-bottom: 30px !important; }

.margin-left-30 {
  margin-left: 30px !important; }

.margin-right-30 {
  margin-right: 30px !important; }

.margin-31 {
  margin: 31px !important; }

.margin-top-31 {
  margin-top: 31px !important; }

.margin-bottom-31 {
  margin-bottom: 31px !important; }

.margin-left-31 {
  margin-left: 31px !important; }

.margin-right-31 {
  margin-right: 31px !important; }

.margin-32 {
  margin: 32px !important; }

.margin-top-32 {
  margin-top: 32px !important; }

.margin-bottom-32 {
  margin-bottom: 32px !important; }

.margin-left-32 {
  margin-left: 32px !important; }

.margin-right-32 {
  margin-right: 32px !important; }

.margin-33 {
  margin: 33px !important; }

.margin-top-33 {
  margin-top: 33px !important; }

.margin-bottom-33 {
  margin-bottom: 33px !important; }

.margin-left-33 {
  margin-left: 33px !important; }

.margin-right-33 {
  margin-right: 33px !important; }

.margin-34 {
  margin: 34px !important; }

.margin-top-34 {
  margin-top: 34px !important; }

.margin-bottom-34 {
  margin-bottom: 34px !important; }

.margin-left-34 {
  margin-left: 34px !important; }

.margin-right-34 {
  margin-right: 34px !important; }

.margin-35 {
  margin: 35px !important; }

.margin-top-35 {
  margin-top: 35px !important; }

.margin-bottom-35 {
  margin-bottom: 35px !important; }

.margin-left-35 {
  margin-left: 35px !important; }

.margin-right-35 {
  margin-right: 35px !important; }

.margin-36 {
  margin: 36px !important; }

.margin-top-36 {
  margin-top: 36px !important; }

.margin-bottom-36 {
  margin-bottom: 36px !important; }

.margin-left-36 {
  margin-left: 36px !important; }

.margin-right-36 {
  margin-right: 36px !important; }

.margin-37 {
  margin: 37px !important; }

.margin-top-37 {
  margin-top: 37px !important; }

.margin-bottom-37 {
  margin-bottom: 37px !important; }

.margin-left-37 {
  margin-left: 37px !important; }

.margin-right-37 {
  margin-right: 37px !important; }

.margin-38 {
  margin: 38px !important; }

.margin-top-38 {
  margin-top: 38px !important; }

.margin-bottom-38 {
  margin-bottom: 38px !important; }

.margin-left-38 {
  margin-left: 38px !important; }

.margin-right-38 {
  margin-right: 38px !important; }

.margin-39 {
  margin: 39px !important; }

.margin-top-39 {
  margin-top: 39px !important; }

.margin-bottom-39 {
  margin-bottom: 39px !important; }

.margin-left-39 {
  margin-left: 39px !important; }

.margin-right-39 {
  margin-right: 39px !important; }

.margin-40 {
  margin: 40px !important; }

.margin-top-40 {
  margin-top: 40px !important; }

.margin-bottom-40 {
  margin-bottom: 40px !important; }

.margin-left-40 {
  margin-left: 40px !important; }

.margin-right-40 {
  margin-right: 40px !important; }

.margin-41 {
  margin: 41px !important; }

.margin-top-41 {
  margin-top: 41px !important; }

.margin-bottom-41 {
  margin-bottom: 41px !important; }

.margin-left-41 {
  margin-left: 41px !important; }

.margin-right-41 {
  margin-right: 41px !important; }

.margin-42 {
  margin: 42px !important; }

.margin-top-42 {
  margin-top: 42px !important; }

.margin-bottom-42 {
  margin-bottom: 42px !important; }

.margin-left-42 {
  margin-left: 42px !important; }

.margin-right-42 {
  margin-right: 42px !important; }

.margin-43 {
  margin: 43px !important; }

.margin-top-43 {
  margin-top: 43px !important; }

.margin-bottom-43 {
  margin-bottom: 43px !important; }

.margin-left-43 {
  margin-left: 43px !important; }

.margin-right-43 {
  margin-right: 43px !important; }

.margin-44 {
  margin: 44px !important; }

.margin-top-44 {
  margin-top: 44px !important; }

.margin-bottom-44 {
  margin-bottom: 44px !important; }

.margin-left-44 {
  margin-left: 44px !important; }

.margin-right-44 {
  margin-right: 44px !important; }

.margin-45 {
  margin: 45px !important; }

.margin-top-45 {
  margin-top: 45px !important; }

.margin-bottom-45 {
  margin-bottom: 45px !important; }

.margin-left-45 {
  margin-left: 45px !important; }

.margin-right-45 {
  margin-right: 45px !important; }

.margin-46 {
  margin: 46px !important; }

.margin-top-46 {
  margin-top: 46px !important; }

.margin-bottom-46 {
  margin-bottom: 46px !important; }

.margin-left-46 {
  margin-left: 46px !important; }

.margin-right-46 {
  margin-right: 46px !important; }

.margin-47 {
  margin: 47px !important; }

.margin-top-47 {
  margin-top: 47px !important; }

.margin-bottom-47 {
  margin-bottom: 47px !important; }

.margin-left-47 {
  margin-left: 47px !important; }

.margin-right-47 {
  margin-right: 47px !important; }

.margin-48 {
  margin: 48px !important; }

.margin-top-48 {
  margin-top: 48px !important; }

.margin-bottom-48 {
  margin-bottom: 48px !important; }

.margin-left-48 {
  margin-left: 48px !important; }

.margin-right-48 {
  margin-right: 48px !important; }

.margin-49 {
  margin: 49px !important; }

.margin-top-49 {
  margin-top: 49px !important; }

.margin-bottom-49 {
  margin-bottom: 49px !important; }

.margin-left-49 {
  margin-left: 49px !important; }

.margin-right-49 {
  margin-right: 49px !important; }

.margin-50 {
  margin: 50px !important; }

.margin-top-50 {
  margin-top: 50px !important; }

.margin-bottom-50 {
  margin-bottom: 50px !important; }

.margin-left-50 {
  margin-left: 50px !important; }

.margin-right-50 {
  margin-right: 50px !important; }

.margin-51 {
  margin: 51px !important; }

.margin-top-51 {
  margin-top: 51px !important; }

.margin-bottom-51 {
  margin-bottom: 51px !important; }

.margin-left-51 {
  margin-left: 51px !important; }

.margin-right-51 {
  margin-right: 51px !important; }

.margin-52 {
  margin: 52px !important; }

.margin-top-52 {
  margin-top: 52px !important; }

.margin-bottom-52 {
  margin-bottom: 52px !important; }

.margin-left-52 {
  margin-left: 52px !important; }

.margin-right-52 {
  margin-right: 52px !important; }

.margin-53 {
  margin: 53px !important; }

.margin-top-53 {
  margin-top: 53px !important; }

.margin-bottom-53 {
  margin-bottom: 53px !important; }

.margin-left-53 {
  margin-left: 53px !important; }

.margin-right-53 {
  margin-right: 53px !important; }

.margin-54 {
  margin: 54px !important; }

.margin-top-54 {
  margin-top: 54px !important; }

.margin-bottom-54 {
  margin-bottom: 54px !important; }

.margin-left-54 {
  margin-left: 54px !important; }

.margin-right-54 {
  margin-right: 54px !important; }

.margin-55 {
  margin: 55px !important; }

.margin-top-55 {
  margin-top: 55px !important; }

.margin-bottom-55 {
  margin-bottom: 55px !important; }

.margin-left-55 {
  margin-left: 55px !important; }

.margin-right-55 {
  margin-right: 55px !important; }

.margin-56 {
  margin: 56px !important; }

.margin-top-56 {
  margin-top: 56px !important; }

.margin-bottom-56 {
  margin-bottom: 56px !important; }

.margin-left-56 {
  margin-left: 56px !important; }

.margin-right-56 {
  margin-right: 56px !important; }

.margin-57 {
  margin: 57px !important; }

.margin-top-57 {
  margin-top: 57px !important; }

.margin-bottom-57 {
  margin-bottom: 57px !important; }

.margin-left-57 {
  margin-left: 57px !important; }

.margin-right-57 {
  margin-right: 57px !important; }

.margin-58 {
  margin: 58px !important; }

.margin-top-58 {
  margin-top: 58px !important; }

.margin-bottom-58 {
  margin-bottom: 58px !important; }

.margin-left-58 {
  margin-left: 58px !important; }

.margin-right-58 {
  margin-right: 58px !important; }

.margin-59 {
  margin: 59px !important; }

.margin-top-59 {
  margin-top: 59px !important; }

.margin-bottom-59 {
  margin-bottom: 59px !important; }

.margin-left-59 {
  margin-left: 59px !important; }

.margin-right-59 {
  margin-right: 59px !important; }

.margin-60 {
  margin: 60px !important; }

.margin-top-60 {
  margin-top: 60px !important; }

.margin-bottom-60 {
  margin-bottom: 60px !important; }

.margin-left-60 {
  margin-left: 60px !important; }

.margin-right-60 {
  margin-right: 60px !important; }

.margin-61 {
  margin: 61px !important; }

.margin-top-61 {
  margin-top: 61px !important; }

.margin-bottom-61 {
  margin-bottom: 61px !important; }

.margin-left-61 {
  margin-left: 61px !important; }

.margin-right-61 {
  margin-right: 61px !important; }

.margin-62 {
  margin: 62px !important; }

.margin-top-62 {
  margin-top: 62px !important; }

.margin-bottom-62 {
  margin-bottom: 62px !important; }

.margin-left-62 {
  margin-left: 62px !important; }

.margin-right-62 {
  margin-right: 62px !important; }

.margin-63 {
  margin: 63px !important; }

.margin-top-63 {
  margin-top: 63px !important; }

.margin-bottom-63 {
  margin-bottom: 63px !important; }

.margin-left-63 {
  margin-left: 63px !important; }

.margin-right-63 {
  margin-right: 63px !important; }

.margin-64 {
  margin: 64px !important; }

.margin-top-64 {
  margin-top: 64px !important; }

.margin-bottom-64 {
  margin-bottom: 64px !important; }

.margin-left-64 {
  margin-left: 64px !important; }

.margin-right-64 {
  margin-right: 64px !important; }

.margin-65 {
  margin: 65px !important; }

.margin-top-65 {
  margin-top: 65px !important; }

.margin-bottom-65 {
  margin-bottom: 65px !important; }

.margin-left-65 {
  margin-left: 65px !important; }

.margin-right-65 {
  margin-right: 65px !important; }

.margin-66 {
  margin: 66px !important; }

.margin-top-66 {
  margin-top: 66px !important; }

.margin-bottom-66 {
  margin-bottom: 66px !important; }

.margin-left-66 {
  margin-left: 66px !important; }

.margin-right-66 {
  margin-right: 66px !important; }

.margin-67 {
  margin: 67px !important; }

.margin-top-67 {
  margin-top: 67px !important; }

.margin-bottom-67 {
  margin-bottom: 67px !important; }

.margin-left-67 {
  margin-left: 67px !important; }

.margin-right-67 {
  margin-right: 67px !important; }

.margin-68 {
  margin: 68px !important; }

.margin-top-68 {
  margin-top: 68px !important; }

.margin-bottom-68 {
  margin-bottom: 68px !important; }

.margin-left-68 {
  margin-left: 68px !important; }

.margin-right-68 {
  margin-right: 68px !important; }

.margin-69 {
  margin: 69px !important; }

.margin-top-69 {
  margin-top: 69px !important; }

.margin-bottom-69 {
  margin-bottom: 69px !important; }

.margin-left-69 {
  margin-left: 69px !important; }

.margin-right-69 {
  margin-right: 69px !important; }

.margin-70 {
  margin: 70px !important; }

.margin-top-70 {
  margin-top: 70px !important; }

.margin-bottom-70 {
  margin-bottom: 70px !important; }

.margin-left-70 {
  margin-left: 70px !important; }

.margin-right-70 {
  margin-right: 70px !important; }

.margin-71 {
  margin: 71px !important; }

.margin-top-71 {
  margin-top: 71px !important; }

.margin-bottom-71 {
  margin-bottom: 71px !important; }

.margin-left-71 {
  margin-left: 71px !important; }

.margin-right-71 {
  margin-right: 71px !important; }

.margin-72 {
  margin: 72px !important; }

.margin-top-72 {
  margin-top: 72px !important; }

.margin-bottom-72 {
  margin-bottom: 72px !important; }

.margin-left-72 {
  margin-left: 72px !important; }

.margin-right-72 {
  margin-right: 72px !important; }

.margin-73 {
  margin: 73px !important; }

.margin-top-73 {
  margin-top: 73px !important; }

.margin-bottom-73 {
  margin-bottom: 73px !important; }

.margin-left-73 {
  margin-left: 73px !important; }

.margin-right-73 {
  margin-right: 73px !important; }

.margin-74 {
  margin: 74px !important; }

.margin-top-74 {
  margin-top: 74px !important; }

.margin-bottom-74 {
  margin-bottom: 74px !important; }

.margin-left-74 {
  margin-left: 74px !important; }

.margin-right-74 {
  margin-right: 74px !important; }

.margin-75 {
  margin: 75px !important; }

.margin-top-75 {
  margin-top: 75px !important; }

.margin-bottom-75 {
  margin-bottom: 75px !important; }

.margin-left-75 {
  margin-left: 75px !important; }

.margin-right-75 {
  margin-right: 75px !important; }

.margin-76 {
  margin: 76px !important; }

.margin-top-76 {
  margin-top: 76px !important; }

.margin-bottom-76 {
  margin-bottom: 76px !important; }

.margin-left-76 {
  margin-left: 76px !important; }

.margin-right-76 {
  margin-right: 76px !important; }

.margin-77 {
  margin: 77px !important; }

.margin-top-77 {
  margin-top: 77px !important; }

.margin-bottom-77 {
  margin-bottom: 77px !important; }

.margin-left-77 {
  margin-left: 77px !important; }

.margin-right-77 {
  margin-right: 77px !important; }

.margin-78 {
  margin: 78px !important; }

.margin-top-78 {
  margin-top: 78px !important; }

.margin-bottom-78 {
  margin-bottom: 78px !important; }

.margin-left-78 {
  margin-left: 78px !important; }

.margin-right-78 {
  margin-right: 78px !important; }

.margin-79 {
  margin: 79px !important; }

.margin-top-79 {
  margin-top: 79px !important; }

.margin-bottom-79 {
  margin-bottom: 79px !important; }

.margin-left-79 {
  margin-left: 79px !important; }

.margin-right-79 {
  margin-right: 79px !important; }

.margin-80 {
  margin: 80px !important; }

.margin-top-80 {
  margin-top: 80px !important; }

.margin-bottom-80 {
  margin-bottom: 80px !important; }

.margin-left-80 {
  margin-left: 80px !important; }

.margin-right-80 {
  margin-right: 80px !important; }

.margin-81 {
  margin: 81px !important; }

.margin-top-81 {
  margin-top: 81px !important; }

.margin-bottom-81 {
  margin-bottom: 81px !important; }

.margin-left-81 {
  margin-left: 81px !important; }

.margin-right-81 {
  margin-right: 81px !important; }

.margin-82 {
  margin: 82px !important; }

.margin-top-82 {
  margin-top: 82px !important; }

.margin-bottom-82 {
  margin-bottom: 82px !important; }

.margin-left-82 {
  margin-left: 82px !important; }

.margin-right-82 {
  margin-right: 82px !important; }

.margin-83 {
  margin: 83px !important; }

.margin-top-83 {
  margin-top: 83px !important; }

.margin-bottom-83 {
  margin-bottom: 83px !important; }

.margin-left-83 {
  margin-left: 83px !important; }

.margin-right-83 {
  margin-right: 83px !important; }

.margin-84 {
  margin: 84px !important; }

.margin-top-84 {
  margin-top: 84px !important; }

.margin-bottom-84 {
  margin-bottom: 84px !important; }

.margin-left-84 {
  margin-left: 84px !important; }

.margin-right-84 {
  margin-right: 84px !important; }

.margin-85 {
  margin: 85px !important; }

.margin-top-85 {
  margin-top: 85px !important; }

.margin-bottom-85 {
  margin-bottom: 85px !important; }

.margin-left-85 {
  margin-left: 85px !important; }

.margin-right-85 {
  margin-right: 85px !important; }

.margin-86 {
  margin: 86px !important; }

.margin-top-86 {
  margin-top: 86px !important; }

.margin-bottom-86 {
  margin-bottom: 86px !important; }

.margin-left-86 {
  margin-left: 86px !important; }

.margin-right-86 {
  margin-right: 86px !important; }

.margin-87 {
  margin: 87px !important; }

.margin-top-87 {
  margin-top: 87px !important; }

.margin-bottom-87 {
  margin-bottom: 87px !important; }

.margin-left-87 {
  margin-left: 87px !important; }

.margin-right-87 {
  margin-right: 87px !important; }

.margin-88 {
  margin: 88px !important; }

.margin-top-88 {
  margin-top: 88px !important; }

.margin-bottom-88 {
  margin-bottom: 88px !important; }

.margin-left-88 {
  margin-left: 88px !important; }

.margin-right-88 {
  margin-right: 88px !important; }

.margin-89 {
  margin: 89px !important; }

.margin-top-89 {
  margin-top: 89px !important; }

.margin-bottom-89 {
  margin-bottom: 89px !important; }

.margin-left-89 {
  margin-left: 89px !important; }

.margin-right-89 {
  margin-right: 89px !important; }

.margin-90 {
  margin: 90px !important; }

.margin-top-90 {
  margin-top: 90px !important; }

.margin-bottom-90 {
  margin-bottom: 90px !important; }

.margin-left-90 {
  margin-left: 90px !important; }

.margin-right-90 {
  margin-right: 90px !important; }

.margin-91 {
  margin: 91px !important; }

.margin-top-91 {
  margin-top: 91px !important; }

.margin-bottom-91 {
  margin-bottom: 91px !important; }

.margin-left-91 {
  margin-left: 91px !important; }

.margin-right-91 {
  margin-right: 91px !important; }

.margin-92 {
  margin: 92px !important; }

.margin-top-92 {
  margin-top: 92px !important; }

.margin-bottom-92 {
  margin-bottom: 92px !important; }

.margin-left-92 {
  margin-left: 92px !important; }

.margin-right-92 {
  margin-right: 92px !important; }

.margin-93 {
  margin: 93px !important; }

.margin-top-93 {
  margin-top: 93px !important; }

.margin-bottom-93 {
  margin-bottom: 93px !important; }

.margin-left-93 {
  margin-left: 93px !important; }

.margin-right-93 {
  margin-right: 93px !important; }

.margin-94 {
  margin: 94px !important; }

.margin-top-94 {
  margin-top: 94px !important; }

.margin-bottom-94 {
  margin-bottom: 94px !important; }

.margin-left-94 {
  margin-left: 94px !important; }

.margin-right-94 {
  margin-right: 94px !important; }

.margin-95 {
  margin: 95px !important; }

.margin-top-95 {
  margin-top: 95px !important; }

.margin-bottom-95 {
  margin-bottom: 95px !important; }

.margin-left-95 {
  margin-left: 95px !important; }

.margin-right-95 {
  margin-right: 95px !important; }

.margin-96 {
  margin: 96px !important; }

.margin-top-96 {
  margin-top: 96px !important; }

.margin-bottom-96 {
  margin-bottom: 96px !important; }

.margin-left-96 {
  margin-left: 96px !important; }

.margin-right-96 {
  margin-right: 96px !important; }

.margin-97 {
  margin: 97px !important; }

.margin-top-97 {
  margin-top: 97px !important; }

.margin-bottom-97 {
  margin-bottom: 97px !important; }

.margin-left-97 {
  margin-left: 97px !important; }

.margin-right-97 {
  margin-right: 97px !important; }

.margin-98 {
  margin: 98px !important; }

.margin-top-98 {
  margin-top: 98px !important; }

.margin-bottom-98 {
  margin-bottom: 98px !important; }

.margin-left-98 {
  margin-left: 98px !important; }

.margin-right-98 {
  margin-right: 98px !important; }

.margin-99 {
  margin: 99px !important; }

.margin-top-99 {
  margin-top: 99px !important; }

.margin-bottom-99 {
  margin-bottom: 99px !important; }

.margin-left-99 {
  margin-left: 99px !important; }

.margin-right-99 {
  margin-right: 99px !important; }

.container, .container-small, .container-medium, .container-large {
  display: grid; }
  .container-small {
    grid-template-columns: minmax(5%, 1fr) minmax(0, 500px) minmax(5%, 1fr); }
  .container-medium {
    grid-template-columns: minmax(5%, 1fr) minmax(0, 1000px) minmax(5%, 1fr); }
  .container-large {
    grid-template-columns: minmax(5%, 1fr) minmax(0, 1200px) minmax(5%, 1fr); }

.ui-table {
  height: 100%;
  overflow: auto;
  background: var(--ui-container);
  display: grid;
  border: 1px solid var(--ui-border);
  font-family: sans-serif;
  border-radius: 4px; }
  .ui-table-actions {
    padding: 10px 8px !important;
    display: flex; }
    .ui-table-actions div:not(:last-child) {
      margin-right: 7px; }
    .ui-table-actions div {
      height: 24px;
      min-width: 24px;
      border-radius: 4px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--ui-border); }
  .ui-table table {
    border-collapse: collapse;
    color: var(--ui-text);
    font-size: 14px; }
    .ui-table table thead tr th {
      padding: 8px;
      text-align: left;
      background: var(--ui-container);
      white-space: nowrap;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      font-weight: 600;
      z-index: 1; }
      .ui-table table thead tr th:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid var(--ui-border); }
    .ui-table table tbody tr {
      background: var(--ui-container);
      transition: .2s;
      cursor: pointer;
      border-bottom: 1px solid var(--ui-border); }
    .ui-table table tbody td {
      padding: 14px 8px;
      white-space: nowrap;
      overflow: auto;
      max-width: 350px; }
      .ui-table table tbody td:not(:last-child) {
        border-right: 1px solid var(--ui-border); }
    .ui-table table tbody tr:hover {
      background: var(--ui-bg) !important; }
  .ui-table-compact table {
    font-size: 12px; }
    .ui-table-compact table tbody td {
      padding: 8px; }
  .ui-table-compact .ui-table-actions {
    padding: 8px !important; }
    .ui-table-compact .ui-table-actions div:not(:last-child) {
      margin-right: 6px; }
    .ui-table-compact .ui-table-actions div {
      height: 20px;
      min-width: 20px; }

.loader {
  position: relative;
  width: 40px;
  height: 40px; }
  .loader:before, .loader:after {
    content: "";
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 100%;
    border: 2px solid transparent;
    border-top-color: var(--ui-bg); }
  .loader:before {
    z-index: 1;
    -webkit-animation: spin 3s linear infinite;
            animation: spin 3s linear infinite;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  .loader:after {
    border: 2px solid var(--ui-bg); }

@-webkit-keyframes spin {
  0% {
    border-top-color: #30AF5F;
    border-top-color: var(--theme-green);
    transform: rotate(0deg); }
  25% {
    border-top-color: #37A9E0;
    border-top-color: var(--theme-blue);
    transform: rotate(360deg); }
  50% {
    border-top-color: #4E59A3;
    border-top-color: var(--theme-purple);
    transform: rotate(720deg); }
  75% {
    border-top-color: #E67F25;
    border-top-color: var(--theme-orange);
    transform: rotate(1080deg); }
  100% {
    border-top-color: #30AF5F;
    border-top-color: var(--theme-green);
    transform: rotate(1440deg); } }

@keyframes spin {
  0% {
    border-top-color: #30AF5F;
    border-top-color: var(--theme-green);
    transform: rotate(0deg); }
  25% {
    border-top-color: #37A9E0;
    border-top-color: var(--theme-blue);
    transform: rotate(360deg); }
  50% {
    border-top-color: #4E59A3;
    border-top-color: var(--theme-purple);
    transform: rotate(720deg); }
  75% {
    border-top-color: #E67F25;
    border-top-color: var(--theme-orange);
    transform: rotate(1080deg); }
  100% {
    border-top-color: #30AF5F;
    border-top-color: var(--theme-green);
    transform: rotate(1440deg); } }

.ui-card {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: var(--ui-container);
  border-radius: 12px; }
  .ui-card-transparent {
    background-color: var(--ui-container-transparent); }
  .ui-card-message {
    text-align: center;
    align-content: center;
    align-items: unset; }
    .ui-card-message p {
      font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
      color: var(--ui-text-lighter);
      font-weight: 300;
      font-size: 16px; }
    .ui-card-message i {
      color: var(--ui-text);
      font-size: 32px;
      margin-bottom: 16px; }

.ui-stat {
  display: grid;
  grid-gap: 14px;
  text-align: left; }
  .ui-stat div {
    display: flex;
    align-items: center;
    font-size: 38px;
    font-weight: 400; }
    .ui-stat div span {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 10px; }
      .ui-stat div span i {
        font-size: 18px;
        color: #30AF5F; }
      .ui-stat div span p {
        font-size: 18px;
        color: var(--ui-text-lighter);
        font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
        margin-left: 5px; }
  .ui-stat-compact div {
    font-size: 28px; }

.ui-card-button {
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 80px auto;
  padding: 36px 12px;
  grid-gap: 12px;
  align-items: center;
  cursor: pointer;
  transition: box-shadow 0.2s; }
  @media only screen and (max-width: 600px) {
    .ui-card-button {
      padding: 20px 8px;
      grid-template-columns: 50px auto; } }
  .ui-card-button-disabled {
    opacity: .25;
    cursor: default; }
  .ui-card-button i {
    font-size: 42px;
    justify-self: center; }
    @media only screen and (max-width: 600px) {
      .ui-card-button i {
        font-size: 28px; } }
  .ui-card-button p {
    color: var(--ui-text);
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    margin: 0; }
    .ui-card-button p:first-child {
      font-weight: 500;
      font-size: 1.1em;
      letter-spacing: -.045em; }
    .ui-card-button p:last-child {
      color: var(--ui-text-lighter);
      font-weight: 300;
      font-size: 14px;
      margin-top: 5px; }
  .ui-card-button:not(.ui-card-button-disabled)::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--ui-text-lighter);
    opacity: 0;
    transition: opacity 0.2s; }
  .ui-card-button:not(.ui-card-button-disabled)::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    padding: 50%;
    width: 32px;
    height: 32px;
    background-color: var(--ui-text-lighter);
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
    transition: opacity 1s, transform 0.5s; }
  .ui-card-button:not(.ui-card-button-disabled):hover::before {
    opacity: 0.02; }
  .ui-card-button:not(.ui-card-button-disabled):focus::before {
    opacity: 0.2; }
  .ui-card-button:not(.ui-card-button-disabled):hover:focus::before {
    opacity: 0.2; }
  .ui-card-button:not(.ui-card-button-disabled):active::after {
    opacity: 0.2;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0s; }
  .ui-card-button-compact {
    grid-template-columns: 60px auto;
    padding: 24px 8px; }
    .ui-card-button-compact i {
      font-size: 28px; }

.ui-card-tile {
  display: grid;
  padding: 30px;
  grid-gap: 5px;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  transition: box-shadow 0.2s;
  position: relative; }
  .ui-card-tile i {
    font-size: 32px; }
  .ui-card-tile p {
    color: var(--ui-text);
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    margin: 0;
    margin-top: 5px;
    font-weight: 400;
    font-size: 1.1em;
    letter-spacing: -.045em; }

.ui-chart {
  font-family: "Roboto", sans-serif; }

#modal > div > div {
  position: fixed;
  z-index: 2;
  background: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  #modal > div > div > div {
    position: relative;
    max-height: 95%;
    max-width: 540px;
    overflow: auto; }

.ui-card-navigation {
  display: flex;
  padding: 20px; }
  .ui-card-navigation a {
    text-decoration: none;
    color: var(--ui-text);
    font-family: "Roboto", sans-serif;
    padding: 12px 20px;
    border-radius: 4px;
    background-color: var(--ui-bg);
    transition: .2s; }
    .ui-card-navigation a:hover {
      background-color: var(--ui-border); }
    .ui-card-navigation a.active {
      background-color: #37A9E0;
      background-color: var(--theme-blue);
      color: white; }
    .ui-card-navigation a:not(:last-child) {
      margin-right: 10px; }

.global-search {
  transition: .2s;
  height: 59px;
  display: grid;
  grid-auto-rows: -webkit-min-content auto;
  grid-auto-rows: min-content auto;
  grid-gap: 20px; }
  .global-search-input {
    position: relative;
    display: flex;
    max-width: 90vw; }
    .global-search-input input {
      width: 425px;
      max-width: 100%;
      font-size: 18px;
      padding: 18px;
      height: unset;
      border-radius: 12px 0 0 12px; }
    .global-search-input button {
      width: 75px;
      border-radius: 0 12px 12px 0px; }
  .global-search > div:last-child:not(.global-search-input) {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; }
    .global-search > div:last-child:not(.global-search-input) .global-search-result {
      border: 1px solid var(--ui-border);
      background: var(--ui-bg);
      padding: 14px 20px;
      border-radius: 12px;
      text-decoration: none;
      transition: .2s;
      margin-bottom: 10px; }
      .global-search > div:last-child:not(.global-search-input) .global-search-result:hover {
        background-color: var(--ui-container);
        cursor: pointer; }
      .global-search > div:last-child:not(.global-search-input) .global-search-result p:last-child {
        color: var(--ui-text-lighter); }
  .global-search.searching {
    padding: 20px;
    height: 500px; }

.energy-region-map {
  fill: var(--ui-border); }

.portal-home-container {
  display: grid;
  grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content auto;
  grid-template-rows: min-content min-content min-content auto;
  grid-gap: 150px; }
  @media only screen and (max-width: 600px) {
    .portal-home-container {
      grid-gap: 75px; } }
  .portal-home-container .navigation {
    display: grid;
    align-items: center;
    grid-template-columns: -webkit-min-content auto -webkit-min-content;
    grid-template-columns: min-content auto min-content;
    padding: 50px 0px 0px 0px; }
    .portal-home-container .navigation > .sign-out-button {
      cursor: pointer;
      padding: 10px;
      display: flex;
      align-items: center;
      font-size: 18px;
      transition: 0.2s;
      border-radius: 2px; }
      .portal-home-container .navigation > .sign-out-button:hover {
        background: var(--ui-container); }
    .portal-home-container .navigation > .user-button {
      justify-self: flex-end;
      display: flex;
      align-items: center;
      font-size: 18px;
      cursor: pointer;
      padding: 10px 20px;
      transition: 0.2s;
      border-radius: 2px; }
      .portal-home-container .navigation > .user-button i {
        margin-right: 10px; }
      .portal-home-container .navigation > .user-button:hover {
        background: var(--ui-container); }
      @media only screen and (max-width: 600px) {
        .portal-home-container .navigation > .user-button p {
          display: none; }
        .portal-home-container .navigation > .user-button i {
          margin-right: 0; } }
  .portal-home-container .heading {
    text-align: center; }
  .portal-home-container .dashboard {
    display: grid;
    grid-template-columns: auto 45%; }
    @media only screen and (max-width: 800px) {
      .portal-home-container .dashboard {
        grid-template-columns: 100%; } }
    @media only screen and (max-width: 1100px) {
      .portal-home-container .dashboard {
        display: flex;
        flex-direction: column; } }
    @media only screen and (max-width: 650px) {
      .portal-home-container .dashboard .apps .tiles {
        grid-template-columns: repeat(2, 1fr); } }
  .portal-home-container .notifications {
    display: grid;
    grid-template-rows: -webkit-min-content auto;
    grid-template-rows: min-content auto; }
  .portal-home-container .apps .tiles {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    gap: 20px; }

.sidebar-layout-container {
  display: grid;
  grid-template-areas: "sidebar navigation" "sidebar routes";
  grid-template-columns: 300px auto;
  grid-template-rows: 130px auto; }
  .sidebar-layout-container .sidebar {
    grid-area: sidebar;
    display: grid;
    padding-top: 47px;
    grid-template-rows: -webkit-min-content auto -webkit-min-content;
    grid-template-rows: min-content auto min-content;
    grid-gap: 75px;
    height: 100vh;
    overflow: auto; }
    .sidebar-layout-container .sidebar-margin {
      margin-left: 40px; }
    .sidebar-layout-container .sidebar .logo {
      display: flex;
      align-items: center; }
      .sidebar-layout-container .sidebar .logo span {
        font-size: 28px;
        padding-bottom: 8px;
        letter-spacing: -2px; }
    .sidebar-layout-container .sidebar .navigation-options {
      padding-left: 0; }
      .sidebar-layout-container .sidebar .navigation-options li > a:not(.active) + .sub-navigation-options {
        display: none; }
      .sidebar-layout-container .sidebar .navigation-options li > a.active + .sub-navigation-options {
        display: block; }
      .sidebar-layout-container .sidebar .navigation-options a {
        display: grid;
        align-items: center;
        grid-template-columns: -webkit-min-content auto;
        grid-template-columns: min-content auto;
        grid-gap: 20px;
        padding-left: 40px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 0 12px 12px 0;
        text-decoration: none;
        margin-bottom: 5px;
        color: var(--ui-text);
        transition: .2s; }
        .sidebar-layout-container .sidebar .navigation-options a i {
          font-size: 20px; }
        .sidebar-layout-container .sidebar .navigation-options a p {
          font-size: 16px; }
        .sidebar-layout-container .sidebar .navigation-options a.active {
          color: white;
          background-color: #30AF5F;
          background-color: var(--theme-green); }
          .sidebar-layout-container .sidebar .navigation-options a.active p {
            color: white; }
        .sidebar-layout-container .sidebar .navigation-options a:not(.active):hover {
          background-color: var(--ui-container); }
      .sidebar-layout-container .sidebar .navigation-options .sub-navigation-options a {
        padding-left: 50px;
        padding-top: 10px;
        padding-bottom: 10px; }
        .sidebar-layout-container .sidebar .navigation-options .sub-navigation-options a i {
          font-size: 16px; }
        .sidebar-layout-container .sidebar .navigation-options .sub-navigation-options a p {
          font-size: 14px; }
        .sidebar-layout-container .sidebar .navigation-options .sub-navigation-options a.active {
          color: var(--ui-text);
          background-color: var(--ui-container); }
          .sidebar-layout-container .sidebar .navigation-options .sub-navigation-options a.active p {
            color: var(--ui-text); }
  .sidebar-layout-container .navigation {
    grid-area: navigation;
    align-self: center;
    align-items: center;
    display: grid;
    grid-template-columns: auto -webkit-min-content;
    grid-template-columns: auto min-content;
    padding-left: 44px;
    padding-right: 44px; }
    .sidebar-layout-container .navigation .search {
      position: relative;
      display: flex; }
      .sidebar-layout-container .navigation .search i {
        color: var(--ui-text);
        position: absolute;
        align-self: center;
        left: 12.5px;
        font-size: 12px; }
      .sidebar-layout-container .navigation .search input {
        min-width: 250px;
        padding-left: 35px;
        font-size: 14px;
        background-color: var(--ui-container); }
  .sidebar-layout-container .routes {
    padding-top: 50px;
    grid-area: routes;
    height: calc(100vh - 130px);
    overflow: auto; }

body {
  background-color: var(--ui-bg); }

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  margin: 0; }

#root {
  min-height: 100vh;
  display: grid; }

.recharts-wrapper {
  font-family: "Roboto", sans-serif; }
  .recharts-wrapper .recharts-legend-item-text {
    color: var(--ui-text); }
  .recharts-wrapper .recharts-default-tooltip {
    background-color: var(--ui-border) !important;
    border: 1px solid var(--ui-text-lighter) !important;
    border-radius: 4px; }
    .recharts-wrapper .recharts-default-tooltip * {
      color: var(--ui-text) !important; }
  .recharts-wrapper .recharts-cartesian-axis-tick {
    font-size: 12px; }

.sync-button {
  position: fixed;
  bottom: 20px;
  right: 20px; }

.sign-in #tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; }

.sign-in .ui-card {
  position: relative;
  z-index: 2; }

