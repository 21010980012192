.ui-card-button {
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 80px auto;
  padding: 36px 12px;
  grid-gap: 12px;
  align-items: center;
  cursor: pointer;
  transition: box-shadow 0.2s;
  @media only screen and (max-width: 600px){
    padding: 20px 8px;
    grid-template-columns: 50px auto;
  }

  &-disabled {
    opacity: .25;
    cursor: default;
  }

  i {
    font-size: 42px;
    justify-self: center;
    @media only screen and (max-width: 600px){
      font-size: 28px;
    }
  }

  p {
    color: var(--ui-text);
    font-family: $font-primary;
    margin: 0;

    &:first-child {
      font-weight: 500;
      font-size: 1.1em;
      letter-spacing: -.045em;
    }

    &:last-child {
      color: var(--ui-text-lighter);
      font-weight: 300;
      font-size: 14px;
      margin-top: 5px;
    }
  }

  &:not(.ui-card-button-disabled) {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: var(--ui-text-lighter);
      opacity: 0;
      transition: opacity 0.2s;
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      padding: 50%;
      width: 32px;
      height: 32px;
      background-color: var(--ui-text-lighter);
      opacity: 0;
      transform: translate(-50%, -50%) scale(1);
      transition: opacity 1s, transform 0.5s;
    }

    &:hover::before {
      opacity: 0.02;
    }

    &:focus::before {
      opacity: 0.2;
    }

    &:hover:focus::before {
      opacity: 0.2;
    }

    &:active::after {
      opacity: 0.2;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0s;
    }
  }
  &-compact {
    i {
      font-size: 28px;
    }
    grid-template-columns: 60px auto;
    padding: 24px 8px;
  }
}