.ui-stat {
  display: grid;
  grid-gap: 14px;
  text-align: left;

  div {
    display: flex;
    align-items: center;
    font-size: 38px;
    font-weight: 400;

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 10px;
      i {
        font-size: 18px;
        color: map-get($colours, green)
      }

      p {
        font-size: 18px;
        color: var(--ui-text-lighter);
        font-family: $font-primary;
        margin-left:5px;
      }
    }
  }

  &-compact {
    div {
      font-size: 28px;
    }
  }
}