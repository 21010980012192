#modal {
  >div {
    >div {
      position: fixed;
      z-index: 2;
      background: rgba(0, 0, 0, 0.7);
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      >div {
        position: relative;
        max-height: 95%;
        max-width: 540px;
        overflow: auto;
      }
    }
  }
}