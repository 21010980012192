@import "apollo.scss";

body {
  margin:0;
}

#root {
  min-height: 100vh;
  display:grid;
}

.recharts-wrapper {
  font-family: $font-secondary;
  .recharts-legend-item-text {
    color: var(--ui-text);
  }
  .recharts-default-tooltip {
    background-color:var(--ui-border)!important;
    border:1px solid var(--ui-text-lighter)!important;
    border-radius: 4px;
     * {
    color:var(--ui-text)!important;
    }
  }
  .recharts-cartesian-axis-tick {
    font-size: 12px;
  }
}

.sync-button {
  position: fixed;
  bottom:20px;
  right:20px;
}

.sign-in {

  #tsparticles {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .ui-card {
    position: relative;
        z-index: 2;
  }

}