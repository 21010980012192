.loader {
  position: relative;
  width: 40px;
  height: 40px;
  &:before, &:after {
    content: "";
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 100%;
    border: 2px solid transparent;
    border-top-color: var(--ui-bg);
  }
  &:before {
    z-index: 1;
    animation: spin 3s linear infinite;
    animation-timing-function: ease-in;
  }
  &:after {
    border: 2px solid var(--ui-bg);
  }
}

@keyframes spin {
  0% {
      border-top-color: var(--theme-green);
      transform: rotate(0deg);
  }
  25% {
    border-top-color: var(--theme-blue);
      transform: rotate(360deg);
  }
  50% {
    border-top-color: var(--theme-purple);
      transform: rotate(720deg);
  }
  75% {
    border-top-color: var(--theme-orange);
      transform: rotate(1080deg);
  }
  100% {
    border-top-color: var(--theme-green);
      transform: rotate(1440deg);
  }
}