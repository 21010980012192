.global-search {
  transition: .2s;
  height:59px;
  display: grid;
  grid-auto-rows: min-content auto;
  grid-gap: 20px;
  &-input {
    position: relative;
    display: flex;
    max-width: 90vw;
    input {
      width:425px;
      max-width: 100%;
      font-size: 18px;
      padding:18px;
      height:unset;
      border-radius: 12px 0 0 12px;
    }
    button {
      width: 75px;
      border-radius: 0 12px 12px 0px;
    }
  }
  > div:last-child:not(.global-search-input) {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .global-search-result {
      border: 1px solid var(--ui-border);
      background: var(--ui-bg);
      padding:14px 20px;
      border-radius: 12px;
      text-decoration: none;
      transition: .2s;
      margin-bottom: 10px;
      &:hover {
        background-color: var(--ui-container);
        cursor: pointer;
      }
      p:last-child {
        color: var(--ui-text-lighter);
      }
    }
  }
  &.searching {
    padding:20px;
    height: 500px;
  }
}