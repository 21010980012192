.ui-card-tile {
  display: grid;
  padding:30px;
  grid-gap: 5px;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  transition: box-shadow 0.2s;
  position: relative;
  i {
    font-size: 32px;
  }
  p {
    color: var(--ui-text);
    font-family: $font-primary;
    margin: 0;
    margin-top:5px;
    font-weight: 400;
    font-size: 1.1em;
    letter-spacing: -.045em;
  }
}