.sidebar-layout-container {
  display: grid;
  grid-template-areas: "sidebar navigation"
    "sidebar routes";
  grid-template-columns: 300px auto;
  grid-template-rows: 130px auto;

  .sidebar {
    grid-area: sidebar;
    display: grid;
    padding-top: 47px;
    grid-template-rows: min-content auto min-content;
    grid-gap: 75px;
    height: 100vh;
    overflow: auto;

    &-margin {
      margin-left: 40px;
    }

    .logo {
      display: flex;
      align-items: center;

      span {
        font-size: 28px;
        padding-bottom: 8px;
        letter-spacing: -2px;
      }
    }

    .navigation-options {
      padding-left:0;
      li >a:not(.active) + .sub-navigation-options {
        display: none;
      }
      li >a.active + .sub-navigation-options {
        display: block;
      }
      a {
        display: grid;
        align-items: center;
        grid-template-columns: min-content auto;
        grid-gap: 20px;
        padding-left: 40px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 0 12px 12px 0;
        text-decoration: none;
        margin-bottom: 5px;
        color: var(--ui-text);

        i {
          font-size: 20px;
        }

        p {
          font-size: 16px;
        }

        transition: .2s;

        &.active {
          color: white;

          p {
            color: white;
          }

          background-color: var(--theme-green);
        }

        &:not(.active):hover {
          background-color: var(--ui-container);
        }
      }

      .sub-navigation-options {
        a {
          padding-left: 50px;
          padding-top: 10px;
          padding-bottom: 10px;

          i {
            font-size: 16px;
          }

          p {
            font-size: 14px;
          }

          &.active {
            color: var(--ui-text);
  
            p {
              color: var(--ui-text);
            }
  
            background-color: var(--ui-container);
          }
        }
      }
    }
  }

  .navigation {
    grid-area: navigation;
    align-self: center;
    align-items: center;
    display: grid;
    grid-template-columns: auto min-content;
    padding-left: 44px;
    padding-right: 44px;

    .search {
      position: relative;
      display: flex;

      i {
        color: var(--ui-text);
        position: absolute;
        align-self: center;
        left: 12.5px;
        font-size: 12px;
      }

      input {
        min-width: 250px;
        padding-left: 35px;
        font-size: 14px;
        background-color: var(--ui-container);
      }
    }
  }

  .routes {
    padding-top: 50px;
    grid-area: routes;
    height: calc(100vh - 130px);
    overflow: auto;
  }
}