.ui-card-navigation {
  display: flex;
  padding: 20px;
  a {
    text-decoration: none;
    color: var(--ui-text);
    font-family: $font-secondary;
    padding:12px 20px;
    border-radius:4px;
    background-color: var(--ui-bg);
    transition: .2s;
    &:hover {
      background-color: var(--ui-border);
    }
    &.active {
      background-color: var(--theme-blue);
      color: white;
    }
    &:not(:last-child){
      margin-right:10px;
    }
  }
}