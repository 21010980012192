@import "categories/variables.scss";
@import "categories/typography.scss";
@import "categories/form.scss";
@import "categories/animations.scss";
@import "categories/colouring.scss";
@import "categories/layouts.scss";

@import "components/table.scss";
@import "components/loader.scss";
@import "components/card.scss";
@import "components/stat.scss";
@import "components/card-button.scss";
@import "components/card-tile.scss";
@import "components/chart.scss";
@import "components/modal.scss";
@import "components/card-navigation.scss";
@import "components/global-search.scss";
@import "components/energy-region-map.scss";

@import "views/portal/home.scss";

@import "layouts/sidebar.scss";

body {
  background-color: var(--ui-bg);
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}