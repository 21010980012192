$colours: (
  green: #30AF5F,
  olive: #A0CC3A,
  purple:#4E59A3,
  blue: #37A9E0,
  orange:#E67F25,
  grey: #4C494B,
  white: #ffffff
);

$light-ui: (
  text-lighter: #948f93,
  border: #e4dee3,
  text: #4C494B,
  bg: #fafafa,
  container: #ffffff,
  container-lighter: #f0f0f0,
  container-transparent: #fffffff7
);

$midnight-ui: (
  text-lighter: #9b9b9b,
  border: #222222,
  text: rgb(255, 255, 255),
  bg: #000000,
  container: #0a0a0a,
  container-lighter: #1f1f1f,
  container-transparent: #181818f7
);

$dark-ui: (
  text-lighter: #5b5b66,
  border: #363644,
  container-lighter: #43434d,
  text: rgb(255, 255, 255),
  bg: #212125,
  container: #28282e,
  container-transparent: #28282ef7
);

:root {
  @each $name, $colour in $colours {
    --theme-#{$name}: #{$colour};
  }
}

[data-theme="light"] {
  @each $name, $colour in $light-ui {
    --ui-#{$name}: #{$colour};
  }
}

[data-theme="midnight"] {
  @each $name, $colour in $midnight-ui {
    --ui-#{$name}: #{$colour};
  }
}

[data-theme="dark"] {
  @each $name, $colour in $dark-ui {
    --ui-#{$name}: #{$colour};
  }
}

$font-primary:"Gotham SSm A", "Gotham SSm B", sans-serif;
$font-secondary:"Roboto", sans-serif;
